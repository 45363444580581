import React from 'react';
import { useTransitionHistory } from 'react-route-transition';
import { connect } from "react-redux";

import './styles/nav.scss';

/**
 * 
 * @param {*} props 
 * @returns 
 */
const Navigation = (props) => {
    const { setBackgroundColor, currentMenu, context, setNeedLogin, setPreviousPage, setClickedMenu, clickedMenu, disableAll, setDisableAll } = props;

    return (
        <div className="home-nav">
            <MainMenu
                setBackgroundColor={setBackgroundColor}
                appContext={context.data}
                currentMenu={currentMenu}
                setNeedLogin={setNeedLogin}
                setPreviousPage={setPreviousPage}
                setClickedMenu={setClickedMenu}
                clickedMenu={clickedMenu}
                disableAll={disableAll}
                setDisableAll={setDisableAll}
            />
        </div>
    )
};

/**
 * 
 * @param {*} props 
 * @returns 
 */
const MainMenu = (props) => {
    const { setBackgroundColor, appContext, currentMenu, setNeedLogin, setPreviousPage, setClickedMenu, clickedMenu, disableAll, setDisableAll } = props;

    const history = useTransitionHistory();

    // when a menu item is clicked.
    const goLink = async (evt, menu) => {
        evt.preventDefault();
        const pathname = window.location.pathname.replace('/', ''); // current location path.

        setNeedLogin(false);
        setClickedMenu(menu);
        setPreviousPage(pathname);

        if (appContext.isMobile) {
            /* for mobile */

            if (menu.slug !== undefined) {
                history.push(`/${menu.slug}`);
            } else {
                history.push(`/${menu.post_name === 'home' ? 'visuals' : menu.post_name}`);
            }

        } else {
            /* for desktop */

            if (pathname === '') setDisableAll(true);

            await animate(menu);

            if (pathname === '') {
                if (menu.slug !== undefined) {
                    history.push(`/${menu.slug}`);
                } else {
                    history.push(`/${menu.post_name === 'home' ? 'visuals' : menu.post_name}`);
                }
                setDisableAll(false);
            } else {
                if (menu.slug !== undefined) {
                    history.push(`/${menu.slug}`);
                } else {
                    history.push(`/${menu.post_name === 'home' ? 'visuals' : menu.post_name}`);
                }

                setBackgroundColor(menu.background_color);

                if (document.querySelector('.background-shadow-wrap')) {
                    setTimeout(() => {
                        const shadowWrap = document.querySelector('.background-shadow-wrap');
                        if (shadowWrap) shadowWrap.remove();
                    });
                }
            }
            setTimeout(() => {
                displayHiddenContent();
            });
        }

    }

    // animate the menu when clicked.
    const animate = (menu) => {
        return new Promise(resolve => {
            // we set timeout where things need to catch up.
            // careful when altering setTimeouts as it will cause bugs.
            // return new Promise(resolve => {
            const menu_nav_item = document.querySelector(`.menu-id-${menu.ID}`);

            // hide content in the back when new menu is opening.
            const main_page_content = document.querySelector('#tab-page .menu-item-main-content') || document.querySelector('#tab-page #home-text');
            const main_page_title = document.querySelector('#tab-page .tab-title');

            if (main_page_content) main_page_content.style.setProperty('opacity', 0, 'important');
            main_page_title.style.setProperty('opacity', 0, 'important');

            // const main = document.querySelector('#main')
            const root = document.querySelector('#root')
            // const elem = menu_nav_item.querySelector(`.menu-color-background`)
            const menuItem = menu_nav_item
            const menuTitle = menuItem.querySelector('.hidden-menu-text')
            const menuItemLiPos = menuItem.getBoundingClientRect()
            const backgroundWrap = document.createElement('DIV')
            const backgroundAnim = document.createElement('DIV')
            // const navigation = document.querySelector('.home-nav')
            // const navigationPos = navigation.getBoundingClientRect()
            const _menuTitle = document.createElement('DIV')
            _menuTitle.classList.add('hide-in-mobile')

            backgroundAnim.style.setProperty('background', menu.background_color, 'important')

            /**
             * Hidden tab title 
             */
            const tabTitle = document.querySelector('#tab-page .tab-title')
            const titlePos = tabTitle.getBoundingClientRect()
            tabTitle.style.setProperty('opacity', 0, 'important')

            /**
             * Background animate
             */
            backgroundWrap.classList.add('background-shadow-wrap')
            backgroundWrap.style.setProperty('z-index', '3')
            setTimeout(() => backgroundWrap.style.setProperty('left', (menuItem.offsetLeft + (menuItemLiPos.width / 2)) + 'px'));
            backgroundWrap.style.setProperty('bottom', '0')

            // Title 
            const computedFontSize = window.getComputedStyle(main_page_title).fontSize;

            _menuTitle.style.setProperty('font-family', menuTitle.style.fontFamily)
            // _menuTitle.style.setProperty('font-size', '1.5vw')
            _menuTitle.style.setProperty('font-size', computedFontSize)
            _menuTitle.style.setProperty('color', menuTitle.style.color)
            _menuTitle.style.setProperty('font-weight', menuTitle.style.fontWeight)
            _menuTitle.style.setProperty('font-style', menuTitle.style.fontStyle)
            _menuTitle.style.setProperty('font-variant', menuTitle.style.fontVariant)
            _menuTitle.style.setProperty('line-height', menuTitle.style.lineHeight)
            _menuTitle.style.setProperty('letter-spacing', menuTitle.style.letterSpacing)
            _menuTitle.style.setProperty('text-align', menuTitle.style.textAlign)
            _menuTitle.style.setProperty('text-decoration', menuTitle.style.textDecoration)
            _menuTitle.style.setProperty('text-transform', menuTitle.style.textTransform)
            _menuTitle.style.setProperty('position', 'absolute')
            _menuTitle.style.setProperty('z-index', 1)
            // _menuTitle.style.setProperty('top', '52pt')
            // _menuTitle.style.setProperty('left', '282pt')
            _menuTitle.style.setProperty('top', `${menuTitle.offsetTop}px`)
            _menuTitle.style.setProperty('left', `${menuTitle.offsetLeft}px`)
            _menuTitle.style.setProperty('transition', '1.2s')
            _menuTitle.style.setProperty('-webkit-transition', '1.2s')
            _menuTitle.innerHTML = menu.title

            backgroundAnim.classList.add('background-shadow-anim')

            backgroundAnim.appendChild(_menuTitle)

            backgroundWrap.appendChild(backgroundAnim)

            // it will either be home or tab page.
            const appendTo = document.querySelector('#tab-page');
            appendTo.appendChild(backgroundWrap);

            setTimeout(() => {
                // conditional for mobile screens
                if (window.innerWidth < 760) {
                    backgroundAnim.style.setProperty('left', `${(menuItemLiPos.x + menuItemLiPos.width) * -1}px`)
                } else {
                    backgroundAnim.style.setProperty('left', `${((menuItemLiPos.left + (menuItemLiPos.width / 2)) - root.offsetLeft) * -1}px`)
                }

                backgroundAnim.style.setProperty('width', `${root.clientWidth}px`)
                backgroundAnim.style.setProperty('height', `${root.clientHeight}px`)

                _menuTitle.style.setProperty('top', `${titlePos.top}px`, 'important')
                _menuTitle.style.setProperty('left', `${titlePos.left - root.offsetLeft}px`, 'important')
            })

            setTimeout(() => {
                menu_nav_item.classList.remove('__animate-menu-background');
                if (main_page_content) main_page_content.style.setProperty('opacity', 1, 'important');
                main_page_title.style.setProperty('opacity', 1, 'important');
                tabTitle.style.setProperty('opacity', 1, 'important');
                resolve();
            }, 1300)
        });
    }

    // controls whether to display the about page content or not.
    const displayHiddenContent = () => {
        const mainContent = document.querySelector('#tab-page .about_page') || document.querySelector('#tab-page .contact-page') || document.querySelector('#tab-page .menu-item-main-content');

        if (mainContent !== null) {
            if (mainContent.className.includes('about_page')) {
                mainContent.style.setProperty('display', 'grid', 'important');
            } else {
                mainContent.style.setProperty('display', 'flex', 'important');
            }
        }
    }

    return (
        <div className="main-menu">
            <div className="main-menu-container">

                {appContext.menuItems.map(item => {
                    // get pathname to compare for hiding text when in current menu page.
                    const pathname = item.slug !== undefined ? item.slug : item.post_name;

                    const menu_bg_style = {
                        '--menu-color': item.background_color,
                        backgroundColor: item.background_color
                    }

                    // build the class names for the element.
                    let isCurrent = '';
                    let isFullColor = '';
                    if (appContext?.currentMenu?.ID === item.ID) isCurrent += '__is-current';
                    if (appContext?.currentMenu?.ID === item.ID) isFullColor += '__is-full-color';

                    const menuClassNames = `menu-item menu-id-${item.ID} ${isCurrent} menu-color-background ${isFullColor}`;

                    // if the user is on the homepage, load this version.
                    if (!currentMenu) {
                        if (appContext.isMobile) {
                            return (
                                <div
                                    id={pathname}
                                    className={menuClassNames}
                                    onClick={disableAll || item.ID === clickedMenu.ID ? null : event => { goLink(event, item) }}
                                    key={item.ID}
                                >
                                    <div className={['menu-color-background', (appContext?.currentMenu?.ID === item.ID ? '__is-full-color' : '')].join(' ')} style={menu_bg_style}></div>
                                    <span className={['menu-text-mobile'].join(' ')} style={{
                                        fontFamily: appContext.menuStyle.font_family,
                                        // fontSize: appContext.menuStyle.font_size + 'vw',
                                        color: item.select_logo_color.toLowerCase(),
                                        fontWeight: appContext.menuStyle.font_weight,
                                        fontStyle: appContext.menuStyle.font_style,
                                        fontVariant: appContext.menuStyle.font_variant,
                                        lineHeight: appContext.menuStyle.line_height + 'px',
                                        textDecoration: appContext.menuStyle.text_decoration,
                                        textTransform: appContext.menuStyle.text_transform,
                                        letterSpacing: appContext.menuStyle.letter_spacing + 'px',
                                        textAlign: appContext.menuStyle.text_align,
                                    }}>{item.title}</span>
                                </div>
                            )
                        } else {
                            return (
                                <div
                                    id={pathname}
                                    className={menuClassNames}
                                    onClick={disableAll ? null : event => { goLink(event, item) }}
                                    key={item.ID}
                                >
                                    <div className={['menu-color-background', (appContext?.currentMenu?.ID === item.ID ? '__is-full-color' : '')].join(' ')} style={menu_bg_style}></div>
                                    <span className={['menu-text'].join(' ')} style={{
                                        fontFamily: appContext.menuStyle.font_family,
                                        // fontSize: appContext.menuStyle.font_size + 'vw',
                                        color: item.select_logo_color.toLowerCase(),
                                        fontWeight: appContext.menuStyle.font_weight,
                                        fontStyle: appContext.menuStyle.font_style,
                                        fontVariant: appContext.menuStyle.font_variant,
                                        lineHeight: appContext.menuStyle.line_height + 'px',
                                        textDecoration: appContext.menuStyle.text_decoration,
                                        textTransform: appContext.menuStyle.text_transform,
                                        letterSpacing: appContext.menuStyle.letter_spacing + 'px',
                                        textAlign: appContext.menuStyle.text_align,
                                        opacity: item.title.toLowerCase() === 'contact' ? 1 : 0,
                                    }}>{item.title}</span>

                                    {/* hidden span for mobile */}
                                    <span
                                        className='hidden-menu-text'
                                        style={{
                                            fontFamily: appContext.menuStyle.font_family,
                                            // fontSize: appContext.menuStyle.font_size + 'vw',
                                            color: item.select_logo_color.toLowerCase(),
                                            fontWeight: appContext.menuStyle.font_weight,
                                            fontStyle: appContext.menuStyle.font_style,
                                            fontVariant: appContext.menuStyle.font_variant,
                                            lineHeight: appContext.menuStyle.line_height + 'px',
                                            textDecoration: appContext.menuStyle.text_decoration,
                                            textTransform: appContext.menuStyle.text_transform,
                                            letterSpacing: appContext.menuStyle.letter_spacing + 'px',
                                            textAlign: appContext.menuStyle.text_align,
                                            opacity: item.title.toLowerCase() === 'contact' ? 1 : 0,
                                        }}
                                    >{item.title}</span>
                                </div>
                            )
                        }
                    }

                    if (appContext.isMobile) {
                        return (
                            <div
                                id={pathname}
                                className={menuClassNames}
                                onClick={disableAll || item.ID === clickedMenu.ID ? null : event => { goLink(event, item) }}
                                key={item.ID}
                            >
                                <div className={['menu-color-background', (appContext?.currentMenu?.ID === item.ID ? '__is-full-color' : '')].join(' ')} style={menu_bg_style}></div>
                                <span className={['menu-text-mobile'].join(' ')} style={{
                                    fontFamily: appContext.menuStyle.font_family,
                                    // fontSize: appContext.menuStyle.font_size + 'vw',
                                    color: item.select_logo_color.toLowerCase(),
                                    fontWeight: appContext.menuStyle.font_weight,
                                    fontStyle: appContext.menuStyle.font_style,
                                    fontVariant: appContext.menuStyle.font_variant,
                                    lineHeight: appContext.menuStyle.line_height + 'px',
                                    textDecoration: appContext.menuStyle.text_decoration,
                                    textTransform: appContext.menuStyle.text_transform,
                                    letterSpacing: appContext.menuStyle.letter_spacing + 'px',
                                    textAlign: appContext.menuStyle.text_align,
                                }}>{item.title}</span>
                            </div>
                        )
                    } else {
                        // this conditional controls whether clickable and showing.
                        if (item.ID !== currentMenu.ID) {
                            return (
                                <div
                                    id={pathname}
                                    className={menuClassNames}
                                    onClick={disableAll || item.ID === clickedMenu.ID ? null : event => { goLink(event, item) }}
                                    key={item.ID}
                                >
                                    <div className={['menu-color-background', (appContext?.currentMenu?.ID === item.ID ? '__is-full-color' : '')].join(' ')} style={menu_bg_style}></div>
                                    <span className={['menu-text'].join(' ')} style={{
                                        fontFamily: appContext.menuStyle.font_family,
                                        // fontSize: appContext.menuStyle.font_size + 'vw',
                                        color: item.select_logo_color.toLowerCase(),
                                        fontWeight: appContext.menuStyle.font_weight,
                                        fontStyle: appContext.menuStyle.font_style,
                                        fontVariant: appContext.menuStyle.font_variant,
                                        lineHeight: appContext.menuStyle.line_height + 'px',
                                        textDecoration: appContext.menuStyle.text_decoration,
                                        textTransform: appContext.menuStyle.text_transform,
                                        letterSpacing: appContext.menuStyle.letter_spacing + 'px',
                                        textAlign: appContext.menuStyle.text_align,
                                        opacity: item.title.toLowerCase() === 'contact' ? 1 : 0,
                                    }}>{item.title}</span>

                                    {/* hidden span for mobile */}
                                    <span
                                        className='hidden-menu-text'
                                        style={{
                                            fontFamily: appContext.menuStyle.font_family,
                                            // fontSize: appContext.menuStyle.font_size + 'vw',
                                            color: item.select_logo_color.toLowerCase(),
                                            fontWeight: appContext.menuStyle.font_weight,
                                            fontStyle: appContext.menuStyle.font_style,
                                            fontVariant: appContext.menuStyle.font_variant,
                                            lineHeight: appContext.menuStyle.line_height + 'px',
                                            textDecoration: appContext.menuStyle.text_decoration,
                                            textTransform: appContext.menuStyle.text_transform,
                                            letterSpacing: appContext.menuStyle.letter_spacing + 'px',
                                            textAlign: appContext.menuStyle.text_align,
                                            opacity: item.title.toLowerCase() === 'contact' ? 1 : 0,
                                        }}
                                    >{item.title}</span>
                                </div>
                            )
                        } else {
                            return (
                                <div
                                    id={pathname}
                                    className={menuClassNames}
                                    key={item.ID}>
                                    <div className={['menu-color-background', (appContext?.currentMenu?.ID === item.ID ? '__is-full-color' : '')].join(' ')} style={menu_bg_style}></div>
                                    <span className={['hidden-menu-text'].join(' ')} style={{
                                        fontFamily: appContext.menuStyle.font_family,
                                        // fontSize: appContext.menuStyle.font_size + 'vw',
                                        color: item.select_logo_color.toLowerCase(),
                                        fontWeight: appContext.menuStyle.font_weight,
                                        fontStyle: appContext.menuStyle.font_style,
                                        fontVariant: appContext.menuStyle.font_variant,
                                        lineHeight: appContext.menuStyle.line_height + 'px',
                                        textDecoration: appContext.menuStyle.text_decoration,
                                        textTransform: appContext.menuStyle.text_transform,
                                        letterSpacing: appContext.menuStyle.letter_spacing + 'px',
                                        textAlign: appContext.menuStyle.text_align,
                                        opacity: item.title.toLowerCase() === 'contact' ? 1 : 0,
                                    }}>{item.title}</span>
                                </div>
                            )
                        }
                    }
                })}
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    context: state.context,
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
