import { SET_LOAD_PAGE, SET_LOAD_PAGE_SUCCESS } from "../actions/types";

const initialState = {
    loading: false,
    status: false,
    error: null
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case SET_LOAD_PAGE:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case SET_LOAD_PAGE_SUCCESS:
            return {
                ...state,
                loading: false,
                status: payload,
                error: null,
            };
        default:
            return state;
    }
}
