/**
 * The login component before the Loader is loaded when the website is opened.
 */

import React, { useEffect, useState } from "react";
import { useTransitionHistory } from "react-route-transition";
import { connect } from "react-redux";

import { setLoadPage } from '../../store/actions/actions';
import { ReactComponent as LoginEye } from "../../assets/media/login-eye.svg";

import './styles/login.scss';

const Login = (props) => {

    const { isPageLoaded, setLoadPage, loginPath, setNeedLogin, context } = props;

    const [showPassword, setShowPassword] = useState(false);
    const history = useTransitionHistory();

    useEffect(() => {
        document.querySelector('.password-input').focus();
    });

    const stripHTML = (html) => {
        let tmp = document.createElement("DIV");
        tmp.innerHTML = html;
        return tmp.textContent || tmp.innerText || "";
    }

    /**
     * When a user hits the enter key on their keyboard on the password input.
     */
    const enterFromInput = (event) => {
        const passwordInput = document.querySelector('.password-input');

        if (event.key === 'Enter' || event.keyCode === 13) {
            // only submit if page is fully loaded.
            if (isPageLoaded.status) {
                /* check password here. */

                const pwInput = event.currentTarget.value; // user input

                // places the token in the users cookies.
                const setTokenCookie = (token, time) => {
                    const expire = stripHTML(time); // expiration in seconds
                    const pathname = window.location.pathname.replace('/', '');

                    if (pathname.includes('/')) {
                        document.cookie = `tellavision-path=${encodeURIComponent(stripHTML(token))};max-age=${expire}`;
                    } else {
                        document.cookie = `tellavision=${encodeURIComponent(stripHTML(token))};max-age=${expire}`;
                    }
                }

                // loop the db data to get passwords. We use data.title.rendered.
                for (const data of context.data.passwords) {
                    const singlePW = data.title.rendered; // what is typed in the title of cpt.

                    if (pwInput === singlePW) {
                        // store token in cookies.
                        const token = data.excerpt.rendered;
                        const time = data.content.rendered;

                        setTokenCookie(token, time);

                        // remove loader.
                        setLoadPage('set-load-page', true);

                        if (loginPath !== undefined) history.push(loginPath); // if component from tab-page.
                        break;
                    } else {

                        // console.error('wrong password');

                        // style passsword when wrong one is entered.
                        passwordInput.style.setProperty('background', 'rgba(255, 0, 0, 0.8)', 'important');
                        setTimeout(() => {
                            passwordInput.style.setProperty('background', '#d5d5d5', 'important');
                        }, 1300);
                    }
                }
            }
        }
    }

    /*
	Open prefilled mail application on user device.
    */
    const RequestPassword = (props) => {
        const { mailto } = props;

        const encoded = encodeURI(mailto); // encode the uri for safety.

        return (
            <div className='request-password'>
                <a href={encoded} target='__blank'>Request Password</a>
            </div>
        )
    }

    /*
	Set State and modify eye icon.
    */
    const eyeClick = (event) => {
	const eyeIcon = event.currentTarget;

	setShowPassword(!showPassword);

	if (showPassword) {
	    eyeIcon.querySelector('path').style.fill = null;
	} else {	
	    eyeIcon.querySelector('path').style.fill = 'red';
	}

	const passInput = document.querySelector('.password-input');
	passInput.focus();
        //document.querySelector('.password-input').focus();
    }

    if (loginPath === undefined) {
        return (
            <div className='login-container'>
                <RequestPassword mailto='mailto:lydia@tellavisionagency.com?subject=Please send me a password for the TellAVision Agency website' />
                <div className='login-content'>
		    <input type={showPassword ? 'text' : 'password'} className='password-input' placeholder='Enter Password' onKeyUp={enterFromInput} />
		    <i
			className="login-eye"
			onClick={eyeClick}
		    >
			<LoginEye />
		    </i>
                </div>
            </div>
        )
    } else {
        return (
            <div className='login-box'>
                <div className='login-container-compact'>
                    <div className='login-wrapper'>
                        <RequestPassword mailto='mailto:lydia@tellavisionagency.com?subject=Please send me a password for the TellAVision Agency website' />
                        <div className='login-content'>
			    <input type={showPassword ? 'text' : 'password'} className='password-input' placeholder='Enter Password' onKeyUp={enterFromInput} />
			    <i
				className="login-eye"
				onClick={eyeClick}
			    >
				<LoginEye />
			    </i>
                        </div>
                    </div>
                    <span className='close-login-box' onClick={(event) => { setNeedLogin(false) }}>X</span>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    isPageLoaded: state.isPageLoaded,
    context: state.context
});

const mapDispatchToProps = {
    setLoadPage
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
