import React, { useState, useEffect, Fragment } from 'react';
import ReactHtmlParser from 'react-html-parser';
import styled from 'styled-components';
import { useParams } from 'react-router';
import { useTransitionHistory } from 'react-route-transition';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
//import ReactGA from 'react-ga';

import Navigation from '../navigation';
import './styles/home.scss';
import './styles/tab-page.scss';
import { ReactComponent as LeftArrow } from '../../assets/media/arrows/left-arrow.svg';
import { ReactComponent as RightArrow } from '../../assets/media/arrows/right-arrow.svg';
import { setContext, setLoadPage } from '../../store/actions/actions';
import Login from '../login';

const TabListingItem = styled.a`
    font-family: ${(props) => (props.tabListingStyle ? props.tabListingStyle.font_family : '')};
    font-size: ${(props) => (props.tabListingStyle ? props.tabListingStyle.font_size + 'px' : '')};
    color: ${(props) => (props.tabListingStyle ? props.tabListingStyle.text_color : '')};
    font-weight: ${(props) => (props.tabListingStyle ? props.tabListingStyle.font_weight : '')};
    font-style: ${(props) => (props.tabListingStyle ? props.tabListingStyle.font_style : '')};
    font-variant: ${(props) => (props.tabListingStyle ? props.tabListingStyle.font_variant : '')};
    line-height: ${(props) => (props.tabListingStyle ? props.tabListingStyle.line_height : '')};
    text-decoration: ${(props) => (props.tabListingStyle ? props.tabListingStyle.text_decoration : '')};
    text-transform: ${(props) => (props.tabListingStyle ? props.tabListingStyle.text_transform : '')};
    &:hover {
        color: ${(props) => (props.tabListingStyle ? props.tabListingStyle.highlight_color : '')};
    }
`;

const PageDataDiv = styled.div`
    font-family: ${(props) => props.pageData.font_family};
    font-size: ${(props) => props.pageData.font_size + 'px'};
    color: ${(props) => props.pageData.text_color};
    font-weight: ${(props) => props.pageData.font_weight};
    font-style: ${(props) => props.pageData.font_style};
    font-variant: ${(props) => props.pageData.font_variant};
    line-height: ${(props) => props.pageData.line_height};
    text-decoration: ${(props) => props.pageData.text_decoration};
    text-transform: ${(props) => props.pageData.text_transform};
    letter-spacing: ${(props) => props.pageData.letter_spacing + 'px'};
    text-align: ${(props) => props.pageData.text_align};
`;

const PageDataMenus = styled.div`
    font-family: ${(props) => props.pageData.font_family};
    font-size: ${(props) => props.pageData.font_size + 'px'};
    color: ${(props) => props.pageData.text_color};
    font-weight: ${(props) => props.pageData.font_weight};
    font-style: ${(props) => props.pageData.font_style};
    font-variant: ${(props) => props.pageData.font_variant};
    line-height: ${(props) => props.pageData.line_height};
    text-decoration: ${(props) => props.pageData.text_decoration};
    text-transform: ${(props) => props.pageData.text_transform};
    letter-spacing: ${(props) => props.pageData.letter_spacing + 'px'};
    text-align: ${(props) => props.pageData.text_align};
    &:hover {
        color: ${(props) => (props.pageData ? props.pageData.highlight_color : '')};
    }
    .active {
        color: ${(props) => (props.pageData ? props.pageData.highlight_color : '')};
    }
`;

const TabPageContent = ({
    pageContent,
    OpenSubmenuItem,
    tabListingStyle,
    getMenuItemContent,
    pageData,
    context,
    allPageData,
    handleHTMLContent,
    needLogin
}) => {
    // contact page.
    if (pageContent.type === 'contact') {
        const font =
            context.themeSettings.contact_content.contact_content_custom_font.option_value === 'none'
                ? context.themeSettings.contact_content.contact_content_add_typography.option_value.font_family
                : context.themeSettings.contact_content.contact_content_custom_font.option_value;
        const pageDataContentStyles = {
            ...context.themeSettings.contact_content.contact_content_add_typography.option_value,
            font_family: font
        };

        return (
            <div
                className="menu-item-main-content contact-page"
                style={{
                    display: 'none'
                    // width: '40%'
                }}
            >
                <PageDataDiv className="page-content-area" pageData={pageDataContentStyles}>
                    {ReactHtmlParser(handleHTMLContent(context.themeSettings.contact_text.contact_text))}
                </PageDataDiv>
            </div>
        );
    }

    // about page.
    if (pageContent.type === 'about-us') {
        // set page-content class
        let pageContentClass = 'page-content-area';

        let pageDataContentStyles;
        const isNewsPage = JSON.stringify(pageData).includes('news_list');
        if (isNewsPage) {
            pageContentClass = 'page-content-area three-grid';
            const font =
                context.themeSettings.about_clients.about_clients_custom_font.option_value === 'none'
                    ? context.themeSettings.about_clients.about_clients_add_typography.option_value.font_family
                    : context.themeSettings.about_clients.about_clients_custom_font.option_value;

            pageDataContentStyles = {
                ...context.themeSettings.about_clients.about_clients_add_typography.option_value,
                font_family: font
            };
        } else {
            const font =
                context.themeSettings.about_content.about_content_custom_font.option_value === 'none'
                    ? context.themeSettings.about_content.about_content_add_typography.option_value.font_family
                    : context.themeSettings.about_content.about_content_custom_font.option_value;

            pageDataContentStyles = {
                ...context.themeSettings.about_content.about_content_add_typography.option_value,
                font_family: font
            };
        }

        const font =
            context.themeSettings.about_menus.about_menus_custom_font.option_value === 'none'
                ? context.themeSettings.about_menus.about_menus_add_typography.option_value.font_family
                : context.themeSettings.about_menus.about_menus_custom_font.option_value;
        const pageDataMenuStyles = {
            ...context.themeSettings.about_menus.about_menus_add_typography.option_value,
            font_family: font,
            highlight_color: context.themeSettings.about_menus.about_menus_highlight_text_color.option_value
        };

        return (
            <div
                className="menu-item-main-content about_page"
                style={{
                    display: 'none',
                    alignItems: 'center'
                }}
            >
                <div className="menu_list">
                    <div className="list_container">
                        {allPageData.aboutMenus.items.map((data, index) => (
                            <PageDataMenus
                                // className='page-content-area'
                                pageData={pageDataMenuStyles}
                            >
                                <div
                                    key={index}
                                    onClick={(event) => getMenuItemContent(event, data)}
                                    className={index === 0 ? 'active' : ''}
                                >
                                    {data.title}
                                </div>
                            </PageDataMenus>
                        ))}
                    </div>
                </div>
                <PageDataDiv className={pageContentClass} pageData={pageDataContentStyles}>
                    {ReactHtmlParser(pageData)}
                </PageDataDiv>
            </div>
        );
    }

    // everything else.
    if (pageContent.type === 'general') {
        return (
            <div
                className="menu-item-main-content"
                style={{
                    display: 'none'
                }}
            >
                {!needLogin && pageContent.content.length > 0 ? (
                    pageContent.content.map((formatedData, index) => (
                        <table
                            key={index}
                            style={{
                                display: 'inline-block',
                                textAlign: 'left',
                                verticalAlign: 'top',
                                padding: '0 10px'
                            }}
                        >
                            <tbody>
                                {formatedData.map((data, index) => {
                                    tabListingStyle.text_color = context.assortedMenu[data.type].menu_text_color;
                                    tabListingStyle.highlight_color =
                                        context.assortedMenu[data.type].highlight_text_color;
                                    return (
                                        <tr key={index}>
                                            <td style={{ padding: '0 20px' }} key={index}>
                                                <TabListingItem
                                                    tabListingStyle={tabListingStyle}
                                                    href="#"
                                                    id={'listing_' + data.id}
                                                    onClick={(event) => OpenSubmenuItem(event, data.id)}
                                                >
                                                    {data.title.rendered}
                                                </TabListingItem>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    ))
                ) : (
                    <p
                        className={`coming-soon-text ${needLogin && 'hide'}`}
                        style={{
                            fontFamily:
                                context.themeSettings.coming_soon_text.coming_soon_text_custom_font.option_value ===
                                'none'
                                    ? context.themeSettings.coming_soon_text.coming_soon_text_add_typography
                                          .option_value.font_family
                                    : context.themeSettings.coming_soon_text.coming_soon_text_custom_font.option_value,
                            fontSize:
                                context.themeSettings.coming_soon_text.coming_soon_text_add_typography.option_value
                                    .font_size + 'px',
                            color: context.themeSettings.coming_soon_text.coming_soon_text_add_typography.option_value
                                .text_color,
                            fontWeight:
                                context.themeSettings.coming_soon_text.coming_soon_text_add_typography.option_value
                                    .font_weight,
                            fontStyle:
                                context.themeSettings.coming_soon_text.coming_soon_text_add_typography.option_value
                                    .font_style,
                            fontVariant:
                                context.themeSettings.coming_soon_text.coming_soon_text_add_typography.option_value
                                    .font_variant,
                            lineHeight:
                                context.themeSettings.coming_soon_text.coming_soon_text_add_typography.option_value
                                    .line_height + 'px',
                            textDecoration:
                                context.themeSettings.coming_soon_text.coming_soon_text_add_typography.option_value
                                    .text_decoration,
                            textTransform:
                                context.themeSettings.coming_soon_text.coming_soon_text_add_typography.option_value
                                    .text_transform,
                            letterSpacing:
                                context.themeSettings.coming_soon_text.coming_soon_text_add_typography.option_value
                                    .letter_spacing + 'px',
                            textAlign:
                                context.themeSettings.coming_soon_text.coming_soon_text_add_typography.option_value
                                    .text_align
                        }}
                    >
                        coming soon...
                    </p>
                )}
            </div>
        );
    }
};

/**
 * Main page container for the whole app.
 */
function PageContainer(props) {
    const { context, aboutMenus, contactContent, homeContent, setContext, loadPageStatus, allPageData } = props;

    const [homeText, setHomeText] = useState('');
    const [backgroundColor, setBackgroundColor] = useState(
        context.data.colors[window.location.pathname.replace('/', '')]
    );

    // everything to do with tab page data below here.
    const { menuTitle } = useParams();
    const [pageData, setPageData] = useState({});
    const [previousPage, setPreviousPage] = useState(null);

    const [needLogin, setNeedLogin] = useState(false);
    const [loginPath, setLoginPath] = useState('');
    const [mobileClass, setMobileClass] = useState('');
    const [perspective, setPerspective] = useState('');
    const [clickedMenu, setClickedMenu] = useState({ ID: null }); // get clicked menu to disable onClick.
    const [disableAll, setDisableAll] = useState(false);

    const history = useTransitionHistory();
    const pathname = window.location.pathname.replace('/', '');
    const currentPage = context.data.assortedMenu[pathname];
    // const history_info = useHistory();

    const formateTabData = (tabData) => {
        let formatedData = [];
        let dataLength = tabData.length;
        if (dataLength <= 5) {
            formatedData = Chunkify(tabData, 1);
        } else if (dataLength <= 12) {
            formatedData = Chunkify(tabData, 2);
        } else {
            formatedData = Chunkify(tabData, 3);
        }
        return formatedData;
    };

    // Divide array into give number of sub-array
    const Chunkify = (a, n) => {
        var len = a.length,
            out = [],
            i = 0,
            size;
        if (len % n === 0) {
            size = Math.floor(len / n);
            while (i < len) {
                out.push(a.slice(i, (i += size)));
            }
        } else {
            while (i < len) {
                size = Math.ceil((len - i) / n--);
                out.push(a.slice(i, (i += size)));
            }
        }
        return out;
    };

    const handleHTMLContent = (string) =>
        string
            .replace(/\\r\\n/g, '<br />')
            .replace(/\\"/g, '"')
            .replace(/\\\//g, '/')
            .replace(/"/g, '')
            .trim();

    const [allContent, setAllContent] = useState({
        visuals: {
            type: 'general',
            content: formateTabData(allPageData.data['visuals'])
        },
        writers: {
            type: 'general',
            content: formateTabData(allPageData.data['writers'])
        },
        '3d-previz': {
            type: 'general',
            content: formateTabData(allPageData.data['3d-previz'])
        },
        'graphic-design': {
            type: 'general',
            content: formateTabData(allPageData.data['graphic-design'])
        },
        worldwide: {
            type: 'general',
            content: formateTabData(allPageData.data['worldwide'])
        },
        corporate: {
            type: 'general',
            content: formateTabData(allPageData.data['corporates'])
        },
        'about-us': {
            type: 'about-us',
            menus: allPageData.aboutMenus,
            content: handleHTMLContent(context.data.themeSettings.about_text.about_text)
        },
        contact: {
            type: 'contact',
            content: contactContent.data
        }
    });

    useEffect(() => {
        (async () => {
            if (!context.data.initialLoadProp) {
                //ReactGA.pageview(props.location.pathname);

                const update = {
                    ...context.data,
                    initialLoadProp: true
                };
                await setContext('context', update);
            }
        })();

        const pathname = window.location.pathname.replace('/', '');
        if (pathname === '') setTimeout(() => ApplyClosingEffectOnLoad());

        setNeedLogin(false);

        setPageData(handleHTMLContent(context.data.themeSettings.about_text.about_text));
        // handle home content.
        setHomeText(context.data.themeSettings.home_text.home_text.option_value);
        // setHomeTextStyle(context.data.themeSettings.home_styles.home_styles_add_typography);

        if (pathname === '' && context.data.isMobile) document.querySelector('.home-nav').classList.add('show-display');

        // keyboard functions
        if (pathname !== '') window.onkeyup = onKeyPresses;

        if (context.data.isMobile) mobilePathClass();

        window.onresize = () => {
            displayHiddenContent();
            getPerspective();
        };
        // window.onpopstate = onBackClick;

        displayHiddenContent();

        (async () => {
            const update = {
                ...context.data,
                currentMenu: pathname !== '' && context.data.assortedMenu[pathname]
            };
            await setContext('context', update);
        })();
    }, [menuTitle, homeContent, aboutMenus, contactContent]);

    const onKeyPresses = (e) => {
        const pathname = window.location.pathname.replace('/', '');
        if (pathname !== '') {
            if (e.key === 'ArrowRight') nextBtn(e, context);
            if (e.key === 'ArrowLeft') prevBtn(e, context);
            if (e.key === 'Escape') history.push('/');
        } else {
            window.onkeyup = null;
        }
    };

    const onBackClick = (e) => {
        const state = e.state;
        if (state !== null) {
            // const update = {
            //     ...context.data,
            //     currentMenu: pathname !== '' && context.data.assortedMenu[pathname]
            // }
            // await setContext('context', update);

            //load content with ajax
            updateBackgroundColor(allContent[pathname].background_color);
            displayHiddenContent();
        }
    };

    // controls whether to display the about page content or not.
    const displayHiddenContent = () => {
        const mainContent =
            document.querySelector('#tab-page .about_page') ||
            document.querySelector('#tab-page .contact-page') ||
            document.querySelector('#tab-page .menu-item-main-content');

        if (mainContent !== null) {
            if (mainContent.className.includes('about_page')) {
                mainContent.style.setProperty('display', 'grid', 'important');
            } else {
                if (context.data.isMobile && window.innerWidth <= 414 && window.innerHeight <= 736) {
                    mainContent.style.setProperty('display', 'grid', 'important');
                } else {
                    mainContent.style.setProperty('display', 'flex');
                }
            }
        }
    };

    // gets the mobile landscape/portrait mode for mobile
    const getPerspective = () => {
        if (context.data.isMobile) {
            if (window.innerWidth <= 414 && window.innerHeight <= 736) {
                setPerspective('portrait');
            }

            if (window.innerWidth <= 414 && window.innerHeight <= 736) {
                setPerspective('landscape');
            }
        }
    };

    const mobilePathClass = () => {
        const pathname = window.location.pathname.replace('/', '');
        if (pathname === '') {
            setMobileClass('mobile-home-logo');
        }
    };

    // Apply closing effect on some elements, if navigated from the tab page
    const ApplyClosingEffectOnLoad = () => {
        if (document.getElementById('home-text'))
            document.getElementById('home-text').classList.remove('home-text-faded');
    };

    const updateBackgroundColor = (current) => {
        if (current?.background_color) setBackgroundColor(current?.background_color);
    };

    // Close current tab on X click
    const CloseMenuItem = (event, context) => {
        event.preventDefault();

        // IIFE to set async await.
        (async () => {
            const update = {
                ...context,
                currentMenu: null
            };
            await setContext('context', update);
        })();

        setDisableAll(true);

        if (context.isMobile) {
            history.push('/');
        } else {
            /* for desktop */
            const main = document.querySelector('#main');
            const menuTitle = document.querySelector(`#tab-page .tab-title`);
            const layer_bg_color = document.createElement('DIV');
            const _menuTitle = document.createElement('DIV');
            _menuTitle.classList.add('hide-in-mobile');

            const TabLogo = document.querySelector('#tab-page #header-logo');
            const TabLogoPos = TabLogo.getBoundingClientRect();
            const _logo = document.createElement('IMG');

            _logo.src = context.themeSettings.site_logo.site_logo[0];

            /**
             * Make logo
             */
            _logo.style.setProperty('width', `${TabLogoPos.width}px`);
            _logo.style.setProperty('left', `${TabLogoPos.x}px`);
            _logo.style.setProperty('top', `${TabLogoPos.y}px`);
            _logo.style.setProperty('padding-top', '25px');
            _logo.style.setProperty('position', 'absolute');
            _logo.style.setProperty('z-index', 9);

            document.body.appendChild(_logo);

            /**
             * Aminate close
             */
            let menuItem = document.querySelector('.menu-item.__is-current');
            let menuTitleText = menuItem.querySelector('.hidden-menu-text');
            menuTitleText.style.setProperty('position', 'relative', 'important');
            // let hiddenMenuTitleText = menuItems.querySelector('.hidden-menu-text');
            let menuPos = menuItem.getBoundingClientRect();
            let menuColor = menuItem.querySelector('.menu-item.__is-current .menu-color-background').style
                .backgroundColor;

            menuItem.style.opacity = 0;

            /**
             * Page title
             */
            const computedFontSize = window.getComputedStyle(menuTitle).fontSize;

            _menuTitle.style.setProperty('font-family', menuTitle.style.fontFamily);
            _menuTitle.style.setProperty('font-size', computedFontSize);
            _menuTitle.style.setProperty('color', menuTitle.style.color);
            _menuTitle.style.setProperty('font-weight', menuTitle.style.fontWeight);
            _menuTitle.style.setProperty('font-style', menuTitle.style.fontStyle);
            _menuTitle.style.setProperty('font-variant', menuTitle.style.fontVariant);
            _menuTitle.style.setProperty('line-height', menuTitle.style.lineHeight);
            _menuTitle.style.setProperty('letter-spacing', menuTitle.style.letterSpacing);
            _menuTitle.style.setProperty('text-align', menuTitle.style.textAlign);
            _menuTitle.style.setProperty('text-decoration', menuTitle.style.textDecoration);
            _menuTitle.style.setProperty('text-transform', menuTitle.style.textTransform);
            _menuTitle.style.setProperty('position', 'absolute');
            _menuTitle.style.setProperty('z-index', 1);
            _menuTitle.style.setProperty('top', `${menuTitle.offsetTop}px`);
            _menuTitle.style.setProperty('left', `${menuTitle.offsetLeft + main.offsetLeft - 15}px`);
            _menuTitle.style.setProperty('transition', '1.2s');
            _menuTitle.style.setProperty('-webkit-transition', '1.2s');
            _menuTitle.innerHTML = menuTitle.innerHTML;

            document.querySelector('#tab-page').style.setProperty('display', 'none');

            layer_bg_color.style.setProperty('position', 'absolute');
            // layer_bg_color.style.setProperty('z-index', '20')
            layer_bg_color.style.setProperty('left', '15px');
            layer_bg_color.style.setProperty('top', '15px');
            layer_bg_color.style.setProperty('width', 'calc(100vw - 30px)');
            layer_bg_color.style.setProperty('height', 'calc(100vh - 15px)');
            layer_bg_color.style.setProperty('background', menuColor);
            layer_bg_color.style.setProperty('transition', '1.2s');
            layer_bg_color.style.setProperty('-webkit-transition', '1.2s');

            layer_bg_color.appendChild(_menuTitle);
            document.body.appendChild(layer_bg_color);

            document.querySelector('#tab-page').style.setProperty('box-shadow', 'none');
            document.querySelector('#tab-page').style.setProperty('background', 'none');

            setTimeout(() => {
                layer_bg_color.style.setProperty('width', `${menuPos.width}px`);
                layer_bg_color.style.setProperty('height', `${menuPos.height}px`);
                layer_bg_color.style.setProperty('left', `${menuPos.x}px`);
                layer_bg_color.style.setProperty('top', `${menuPos.y}px`);

                const hiddenMenuText = menuItem.querySelector('.hidden-menu-text');

                _menuTitle.style.setProperty('font-size', '1.5vw');
                _menuTitle.style.setProperty('top', `${hiddenMenuText.offsetTop}px`);
                _menuTitle.style.setProperty('left', `${hiddenMenuText.offsetLeft}px`);
            });

            setTimeout(() => {
                history.push('/');
                layer_bg_color.remove();
                _logo.remove();
                setDisableAll(false);
            }, 1300);
        }
    };

    // Navigate to new sub menu on click
    const OpenSubmenuItem = (event, id) => {
        event.preventDefault();

        setLoginPath(`/${menuTitle}/${id}`);

        if (loadPageStatus.status) {
            history.push(`/${menuTitle}/${id}`);
        } else {
            setNeedLogin(true);
        }
    };

    // Show animation on next button click
    const nextBtn = (evt, context) => {
        evt.preventDefault();

        const menusObject = context.data.menuItems;
        let nextPage;

        const pathname = window.location.pathname.replace('/', '');
        setPreviousPage(pathname);

        if (pathname === 'contact') {
            nextPage = menusObject[0];
            history.push('visuals');
            updateBackgroundColor(nextPage);
        } else {
            setClickedMenu({ ID: null });

            nextPage = menusObject[currentPage.next_menu.menu_order - 1];

            const slug = nextPage.slug !== undefined ? nextPage.slug : nextPage.post_name;
            if (slug) history.push(slug);

            updateBackgroundColor(nextPage);
        }
    };

    // Show animation on previous button click
    const prevBtn = (evt, context) => {
        evt.preventDefault();

        const menusObject = context.data.menuItems;
        let prevPage;

        const pathname = window.location.pathname.replace('/', '');
        setPreviousPage(pathname);

        setClickedMenu({ ID: null });

        // this conditional allows endless loop when using nav arrows.
        if (pathname === 'visuals') {
            prevPage = menusObject[menusObject.length - 1];
            const slug = prevPage.slug !== undefined ? prevPage.slug : prevPage.post_name;
            history.push(slug);
            updateBackgroundColor(prevPage);
            return;
        }

        prevPage = menusObject[currentPage.prev_menu.menu_order - 1];
        const slug = prevPage.slug !== undefined ? prevPage.slug : prevPage.post_name;

        if (slug === 'home') {
            history.push('visuals');
        } else {
            history.push(slug);
        }

        updateBackgroundColor(prevPage);
    };

    // Get menu item content if tab content is similar to menu
    const getMenuItemContent = async (event, menuDetails) => {
        event.preventDefault();

        // remove and add active class to menu items.
        const parent = event.target.parentElement.parentElement;
        for (const menu of parent.children) {
            menu.querySelector('div').classList.remove('active');
        }
        event.target.classList.add('active');

        switch (menuDetails.ID) {
            case 278:
                setPageData(handleHTMLContent(context.data.themeSettings.about_text.about_text));
                break;
            case 277:
                setPageData(context.data.themeSettings.about_news.about_news_text.option_value);
                break;
            case 276:
                setPageData(context.data.themeSettings.about_clients_text.about_clients_text.option_value);
                break;
            default:
                break;
        }
    };

    const boxShadow = `-50vw 0px 0px 0 ${backgroundColor}, 50vw 0px 0px 0 ${backgroundColor}`;

    return (
        <div className="home-content">
            <div
                id="tab-page"
                style={{
                    background: pathname !== '' && backgroundColor,
                    boxShadow: pathname !== '' && boxShadow
                }}
            >
                <Helmet>
                    <title>
                        {currentPage ? context.data.appName + ' | ' + currentPage.title : context.data.appName}
                    </title>
                </Helmet>

                <div
                    // className="header_logo_img"
                    className={`header_logo_img ${context.data.isMobile && pathname === '' ? 'mobile-home-logo' : ''}`}
                    onClick={pathname === '' ? null : (event) => CloseMenuItem(event, context.data)}
                    style={{
                        cursor: pathname !== '' ? 'pointer' : 'default',
                        maxWidth: `${context.data.logoSize}pt`,
                        width: `${context.data.logoSize}pt`
                    }}
                >
                    <img
                        // id={pathname !== '' && perspective !== 'portrait' ? 'header-logo' : 'mobile-home-logo'}
                        id="header-logo"
                        src={
                            currentPage && currentPage.select_logo_color === 'White'
                                ? context.data.themeSettings.white_logo.white_logo[0]
                                : context.data.themeSettings.site_logo.site_logo[0]
                        }
                        alt="logo"
                        style={{
                            maxWidth: `${context.data.themeSettings.logo_size.logo_size.option_value}pt`,
                            width: `${context.data.themeSettings.logo_size.logo_size.option_value}pt`
                        }}
                    />
                </div>

                {/* home-page template */}
                {pathname === '' && (
                    <Fragment>
                        <p
                            className="tab-title"
                            style={{
                                fontFamily: context.data.menuStyle ? context.data.menuStyle.font_family : 'inherit',
                                fontSize: context.data.menuStyle ? context.data.menuStyle.font_size + 'px' : 'inherit',
                                color: currentPage?.select_logo_color,
                                fontWeight: context.data.menuStyle ? context.data.menuStyle.font_weight : 'inherit',
                                fontStyle: context.data.menuStyle ? context.data.menuStyle.font_style : 'inherit',
                                fontVariant: context.data.menuStyle ? context.data.menuStyle.font_variant : 'inherit',
                                lineHeight: context.data.menuStyle
                                    ? context.data.menuStyle.line_height + 'px'
                                    : 'inherit',
                                textDecoration: context.data.menuStyle
                                    ? context.data.menuStyle.text_decoration
                                    : 'inherit',
                                textTransform: context.data.menuStyle
                                    ? context.data.menuStyle.text_transform
                                    : 'inherit',
                                letterSpacing: context.data.menuStyle
                                    ? context.data.menuStyle.letter_spacing + 'px'
                                    : 'inherit',
                                textAlign: context.data.menuStyle ? context.data.menuStyle.text_align : 'inherit',
                                left: context.data.themeSettings.tab_title.tab_title_left.option_value + 'pt',
                                top: context.data.themeSettings.tab_title.tab_title_top.option_value + 'pt'
                            }}
                        ></p>
                        <div
                            id="home-text"
                            className="home-text-faded"
                            style={{
                                fontFamily:
                                    context.data.themeSettings.home_styles.home_styles_custom_font.option_value ===
                                    'none'
                                        ? context.data.themeSettings.home_styles.home_styles_add_typography.option_value
                                              .font_family
                                        : context.data.themeSettings.home_styles.home_styles_custom_font.option_value,
                                fontSize: context.data
                                    ? context.data.themeSettings.home_styles.home_styles_add_typography.option_value
                                          .font_size + 'px'
                                    : 'inherit',
                                color: context.data
                                    ? context.data.themeSettings.home_styles.home_styles_add_typography.option_value
                                          .text_color
                                    : 'inherit',
                                fontWeight: context.data
                                    ? context.data.themeSettings.home_styles.home_styles_add_typography.option_value
                                          .font_weight
                                    : 'inherit',
                                fontStyle: context.data
                                    ? context.data.themeSettings.home_styles.home_styles_add_typography.option_value
                                          .font_style
                                    : 'inherit',
                                fontVariant: context.data
                                    ? context.data.themeSettings.home_styles.home_styles_add_typography.option_value
                                          .font_variant
                                    : 'inherit',
                                lineHeight: context.data
                                    ? context.data.themeSettings.home_styles.home_styles_add_typography.option_value
                                          .line_height + 'px'
                                    : 'inherit',
                                textDecoration: context.data
                                    ? context.data.themeSettings.home_styles.home_styles_add_typography.option_value
                                          .text_decoration
                                    : 'inherit',
                                textTransform: context.data
                                    ? context.data.themeSettings.home_styles.home_styles_add_typography.option_value
                                          .text_transform
                                    : 'inherit',
                                letterSpacing: context.data
                                    ? context.data.themeSettings.home_styles.home_styles_add_typography.option_value
                                          .letter_spacing + 'px'
                                    : 'inherit',
                                textAlign: context.data
                                    ? context.data.themeSettings.home_styles.home_styles_add_typography.option_value
                                          .text_align
                                    : 'inherit',
                                // top: 0,
                                margin: 'none'
                            }}
                        >
                            {ReactHtmlParser(handleHTMLContent(context.data.themeSettings.home_text.home_text))}
                        </div>
                    </Fragment>
                )}

                {/* tab-page template */}
                {pathname !== '' && (
                    <Fragment>
                        <p
                            className="tab-title"
                            onClick={(event) => (currentPage ? CloseMenuItem(event, context.data) : null)}
                            style={{
                                fontFamily: context.data.menuStyle ? context.data.menuStyle.font_family : 'inherit',
                                fontSize: context.data.menuStyle ? context.data.menuStyle.font_size + 'px' : 'inherit',
                                color: currentPage?.select_logo_color,
                                fontWeight: context.data.menuStyle ? context.data.menuStyle.font_weight : 'inherit',
                                fontStyle: context.data.menuStyle ? context.data.menuStyle.font_style : 'inherit',
                                fontVariant: context.data.menuStyle ? context.data.menuStyle.font_variant : 'inherit',
                                lineHeight: context.data.menuStyle
                                    ? context.data.menuStyle.line_height + 'px'
                                    : 'inherit',
                                textDecoration: context.data.menuStyle
                                    ? context.data.menuStyle.text_decoration
                                    : 'inherit',
                                textTransform: context.data.menuStyle
                                    ? context.data.menuStyle.text_transform
                                    : 'inherit',
                                letterSpacing: context.data.menuStyle
                                    ? context.data.menuStyle.letter_spacing + 'px'
                                    : 'inherit',
                                textAlign: context.data.menuStyle ? context.data.menuStyle.text_align : 'inherit',
                                left: context.data.themeSettings.tab_title.tab_title_left.option_value + 'pt',
                                top: context.data.themeSettings.tab_title.tab_title_top.option_value + 'pt'
                            }}
                        >
                            {currentPage.title}
                        </p>
                        {/* <div className="close_right"> */}
                        <span className="close-btn" onClick={(event) => CloseMenuItem(event, context.data)}>
                            X
                        </span>
                        {/* </div> */}
                        <div
                            className={
                                currentPage && currentPage.ID === 16
                                    ? ['prev_btn', 'about_prev_next_btn'].join(' ')
                                    : 'prev_btn'
                            }
                            style={{
                                fill:
                                    currentPage !== null && currentPage.prev_menu !== undefined
                                        ? currentPage.prev_menu.background_color
                                        : 'inherit'
                            }}
                        >
                            <LeftArrow onClick={(event) => prevBtn(event, context)} />
                        </div>
                        <br />
                        <div
                            className={
                                currentPage && currentPage.ID === 16
                                    ? ['next_btn', 'about_prev_next_btn'].join(' ')
                                    : 'next_btn'
                            }
                            style={{
                                fill:
                                    currentPage !== null && currentPage.next_menu !== undefined
                                        ? currentPage.next_menu.background_color
                                        : 'inherit'
                            }}
                        >
                            <RightArrow onClick={(event) => nextBtn(event, context)} />
                        </div>

                        {needLogin && <Login loginPath={loginPath} setNeedLogin={setNeedLogin} />}

                        {pathname !== previousPage && (
                            <TabPageContent
                                pageContent={allContent[pathname]}
                                tabListingStyle={{
                                    ...context.data.themeSettings.post_listing.post_listing_add_typography.option_value,
                                    font_family:
                                        context.data.themeSettings.post_listing.post_listing_custom_font
                                            .option_value === 'none'
                                            ? context.data.themeSettings.post_listing.post_listing_add_typography
                                                  .option_value.font_family
                                            : context.data.themeSettings.post_listing.post_listing_custom_font
                                                  .option_value
                                }}
                                OpenSubmenuItem={OpenSubmenuItem}
                                getMenuItemContent={getMenuItemContent}
                                pageData={pageData}
                                context={context.data}
                                allPageData={allPageData.data}
                                handleHTMLContent={handleHTMLContent}
                                needLogin={needLogin}
                            />
                        )}
                    </Fragment>
                )}
            </div>
            <Navigation
                setBackgroundColor={setBackgroundColor}
                currentMenu={currentPage}
                setNeedLogin={setNeedLogin}
                setPreviousPage={setPreviousPage}
                setClickedMenu={setClickedMenu}
                clickedMenu={clickedMenu}
                disableAll={disableAll}
                setDisableAll={setDisableAll}
            />
        </div>
    );
}

const mapStateToProps = (state) => ({
    visuals: state.visuals,
    writers: state.writers,
    previz3d: state.previz3d,
    graphicDesign: state.graphicDesign,
    worldwide: state.worldwide,
    corporate: state.corporate,
    aboutMenus: state.aboutMenus,
    menuItemStyle: state.menuItemStyle,
    aboutContent: state.aboutContent,
    contactContent: state.contactContent,
    homeContent: state.homeContent,
    context: state.context,
    fetchInnerAbout: state.fetchInnerAbout,
    allPageData: state.allPageData,
    loadPageStatus: state.setLoadPage
});

const mapDispatchToProps = {
    setContext,
    setLoadPage
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PageContainer));
