import React, { useEffect, useState } from 'react';
import { Router, Route } from 'react-router-dom';
import { RouteTransitionProvider } from 'react-route-transition';
import { createBrowserHistory } from 'history';
import APIService from './services';
import { connect } from 'react-redux';
//import ReactGA from 'react-ga';
import { isMobile } from 'react-device-detect';
import GoogleFontLoader from 'react-google-font-loader';

import {
    setContext,
    getPageData,
    fetchAboutMenus,
    fetchPrimaryMenu,
    fetchPageContent,
    fetchHomeContent,
    fetchPasswords,
    setIsPageLoaded,
    fetchInnerAbout,
    getAllPageData
} from './store/actions/actions';

import Loader from './components/loader';
import Login from './components/login';
import './App.css';
import './assets/styles/animations.scss';
import PageContainer from './components/page-container';
import SubTabPage from './components/sub-tab-page';
import { setLoadPage } from './store/actions/actions';
//import { CustomFontLoader } from './components/custom-font-loader';

// cache busting
import packageJson from '../package.json';

//const TRACKING_ID = 'UA-26025325-1';
//ReactGA.initialize(TRACKING_ID);

const history = createBrowserHistory();

// Initialize google analytics page view tracking
//history.listen((location) => {
    //ReactGA.set({ page: location.pathname }); // Update the user's current page
    //ReactGA.pageview(location.pathname); // Record a pageview for the given page
    //// ReactGA.timing();
    //// ReactGA.event();
//});

function App(props) {
    const {
        setContext,
        getPageData,
        fetchAboutMenus,
        fetchPrimaryMenu,
        fetchPageContent,
        fetchHomeContent,
        fetchPasswords,
        setIsPageLoaded,
        fetchInnerAbout,
        isPageLoaded,
        loadPageStatus,
        getAllPageData
    } = props;

    const [googleFonts, setGoogleFonts] = useState({});

    useEffect(() => {
        caching();
        const body = document.querySelector('body');

        // grab #root element and add `.mobile`.
        if (isMobile) {
            require('./assets/styles/mobile/screensizes.scss');
            body.classList.add('mobile');
        }

        (async () => {
            // get main data for theme-settings, menu-items, etc.
            const fetchAll = await Promise.all([
                APIService.fetchMainData(),
                APIService.fetchPrimaryMenu(),
                APIService.fetchPasswords()
            ]);
            const mainDataFetch = fetchAll[0];
            const themeSettings = mainDataFetch.data.theme_settings;

            // set tab color for safari 15
            //const headEle = document.querySelector('head');
            //const sfriMeta = document.createElement('meta');

            //sfriMeta.name = 'theme-color';
            //const sfriTabColor = themeSettings.sfri15_tab_color.sfri15_tab_color.option_value;
            //sfriMeta.content = sfriTabColor;
            //
            //headEle.append(sfriMeta);

            // fetch the main menu objects.
            const fetchMenus = fetchAll[1];
            const primaryMenu = fetchMenus.data.items;

            const fetchPasswords = fetchAll[2];
            const passwords = fetchPasswords.data;

            // get bg colors in the beginning to prevent flickering.
            const colors = {};
            for (const menu of primaryMenu) {
                let postName = '';
                switch (menu.post_name) {
                    case 'home':
                        postName = 'visuals';
                        break;
                    case '16':
                        postName = 'about-us';
                        break;
                    case '19':
                        postName = 'contact';
                        break;
                    default:
                        postName = menu.post_name;
                        break;
                }

                colors[postName] = menu.background_color;
            }

            const customFontMenu = themeSettings.menu_styles.menu_styles_custom_font.option_value;
            let buildMenuStyle = {};
            if (customFontMenu === 'none') {
                buildMenuStyle = {
                    ...themeSettings.menu_styles.menu_styles_add_typography.option_value
                };
            } else {
                buildMenuStyle = {
                    ...themeSettings.menu_styles.menu_styles_add_typography.option_value,
                    font_family: customFontMenu
                };
            }
            // get menu style from a single menu item for data needed in homepage.
            const menuStyle = buildMenuStyle;

            // get all menu items and build with key with same name as url path. This is to prevent flickering.
            const buildMenuItems = {};
            let index = 0;
            for (const item of primaryMenu) {
                // setup next and prev menu.
                const primaryMenuLength = primaryMenu;
                const prevMenu = primaryMenu[index - 1] ? primaryMenu[index - 1] : primaryMenu[primaryMenuLength - 1];
                const nextMenu = primaryMenu[index + 1] ? primaryMenu[index + 1] : primaryMenu[0];
                const selectedMenu = { ...item, ...{ prev_menu: prevMenu }, ...{ next_menu: nextMenu } };

                // build the menu items.
                const key = item.slug !== undefined ? item.slug : item.post_name;
                if (key === 'home') {
                    // visuals
                    buildMenuItems['visuals'] = selectedMenu;
                } else {
                    buildMenuItems[key] = selectedMenu;
                }
                index++;
            }

            // import google fonts. we loop themeSettings and look for all font_family in themeSettings.
            const googleFontsList = [];
            for (const outer in themeSettings) {
                for (const key in themeSettings[outer]) {
                    if (key.includes('custom_font')) {
                        const font = themeSettings[outer][key].option_value;
                        if (font !== 'none') googleFontsList.push(font);
                    }

                    if (key.includes('add_typography')) {
                        const font = themeSettings[outer][key].option_value.font_family;
                        if (font) googleFontsList.push(font);
                    }
                }
            }

            const noDupeFonts = [...new Set(googleFontsList)];

            const buildFontsData = {};
            for (const font of noDupeFonts) buildFontsData[font] = {};

            setGoogleFonts(
                noDupeFonts.map((font) => {
                    return { font, weights: [100, 300, 400, 600, 700, 800] };
                })
            );

            const context = {
                appName: 'Tellavision Agency',
                themeSettings: themeSettings,
                logoSize: themeSettings.logo_size.logo_size.option_value,
                logo: themeSettings.site_logo.site_logo.option_value,
                whiteLogo: themeSettings.white_logo.white_logo.option_value,
                menuStyle: menuStyle,
                menuItems: primaryMenu, // @todo - can get rid of PrimaryMenu and etc.
                currentMenu: null, // the menu that we are navigating from
                isMobile: isMobile,
                colors: colors,
                comingSoonStyle: themeSettings.coming_soon_text.coming_soon_text_add_typography.option_value,
                passwords: passwords,
                assortedMenu: buildMenuItems,
                initialLoadProp: false,
                loadFonts: noDupeFonts,
                isContentLoaded: false
            };

            //CustomFontLoader(context.themeSettings.font_list);

            await Promise.all([setContext('context', context), getAllPageData('page-data')]);

            setContext('context', {
                ...context,
                isContentLoaded: true
            });

            // only set isPageLoaded if user is viewing the screen.
            // this is needed for FOUT. we set isPageLoaded in Loader.js if user is away.
            const windowStatus = document.visibilityState;
            if (windowStatus === 'visible') {
                setIsPageLoaded('is-page-loaded');
            }
        })();
    }, [
        setContext,
        getPageData,
        fetchAboutMenus,
        fetchPrimaryMenu,
        fetchPageContent,
        fetchInnerAbout,
        fetchHomeContent,
        fetchPasswords,
        setIsPageLoaded,
        getAllPageData
    ]);

    const caching = () => {
        let version = localStorage.getItem('version');
        if (version != packageJson.version) {
            if ('caches' in window) {
                caches.keys().then((names) => {
                    // Delete all the cache files
                    names.forEach((name) => {
                        caches.delete(name);
                    });
                });

                // Makes sure the page reloads. Changes are only visible after you refresh.
                window.location.reload(true);
            }

            localStorage.clear();
            localStorage.setItem('version', packageJson.version);
        }
    };

    return (
        <div id="main">
            {Object.keys(googleFonts).length > 0 && <GoogleFontLoader fonts={googleFonts} />}
            <Loader />
            {isPageLoaded.status && (
                <Router history={history}>
                    <RouteTransitionProvider>
                        <Route path="/" exact>
                            <PageContainer />
                        </Route>
                        <Route path="/:menuTitle" exact>
                            <PageContainer />
                        </Route>
                        <Route path="/:menuTitle/:subId" exact>
                            {!loadPageStatus.status && <Login />}
                            {loadPageStatus.status && <SubTabPage />}
                        </Route>
                    </RouteTransitionProvider>
                </Router>
            )}
        </div>
    );
}

const mapStateToProps = (state) => ({
    isPageLoaded: state.isPageLoaded,
    loadPageStatus: state.setLoadPage,
    getAllPageData: state.getAllPageData
});

const mapDispatchToProps = {
    setContext,
    getPageData,
    fetchAboutMenus,
    fetchPrimaryMenu,
    fetchPageContent,
    fetchHomeContent,
    fetchPasswords,
    setIsPageLoaded,
    fetchInnerAbout,
    setLoadPage,
    getAllPageData
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
