export const GET_PAGE_DATA = 'GET_PAGE_DATA';
export const GET_PAGE_DATA_SUCCESS = 'GET_PAGE_DATA_SUCCESS';
export const GET_PAGE_DATA_ERROR = 'GET_PAGE_DATA_ERROR';

export const GET_VISUALS_DATA = 'GET_VISUALS_DATA';
export const GET_VISUALS_DATA_SUCCESS = 'GET_VISUALS_DATA_SUCCESS';
export const GET_VISUALS_DATA_ERROR = 'GET_VISUALS_DATA_ERROR';

export const GET_WRITERS_DATA = 'GET_WRITERS_DATA';
export const GET_WRITERS_DATA_SUCCESS = 'GET_WRITERS_DATA_SUCCESS';
export const GET_WRITERS_DATA_ERROR = 'GET_WRITERS_DATA_ERROR';

export const GET_3D_PREVIZ_DATA = 'GET_3D_PREVIZ_DATA';
export const GET_3D_PREVIZ_DATA_SUCCESS = 'GET_3D_PREVIZ_DATA_SUCCESS';
export const GET_3D_PREVIZ_DATA_ERROR = 'GET_3D_PREVIZ_DATA_ERROR';

export const GET_GRAPHIC_DESIGN_DATA = 'GET_GRAPHIC_DESIGN_DATA';
export const GET_GRAPHIC_DESIGN_DATA_SUCCESS = 'GET_GRAPHIC_DESIGN_DATA_SUCCESS';
export const GET_GRAPHIC_DESIGN_DATA_ERROR = 'GET_GRAPHIC_DESIGN_DATA_ERROR';

export const GET_WORLDWIDE_DATA = 'GET_WORLDWIDE_DATA';
export const GET_WORLDWIDE_DATA_SUCCESS = 'GET_WORLDWIDE_DATA_SUCCESS';
export const GET_WORLDWIDE_DATA_ERROR = 'GET_WORLDWIDE_DATA_ERROR';

export const GET_CORPORATE_DATA = 'GET_CORPORATE_DATA';
export const GET_CORPORATE_DATA_SUCCESS = 'GET_CORPORATE_DATA_SUCCESS';
export const GET_CORPORATE_DATA_ERROR = 'GET_CORPORATE_DATA_ERROR';

export const GET_ABOUT_MENUS_DATA = 'GET_ABOUT_MENUS_DATA';
export const GET_ABOUT_MENUS_DATA_SUCCESS = 'GET_ABOUT_MENUS_DATA_SUCCESS';
export const GET_ABOUT_MENUS_DATA_ERROR = 'GET_ABOUT_MENUS_DATA_ERROR';

export const GET_PRIMARY_MENU_DATA = 'GET_PRIMARY_MENU_DATA';
export const GET_PRIMARY_MENU_DATA_SUCCESS = 'GET_PRIMARY_MENU_DATA_SUCCESS';
export const GET_PRIMARY_MENU_DATA_ERROR = 'GET_PRIMARY_MENU_DATA_ERROR';

export const GET_ABOUT_CONTENT_DATA = 'GET_ABOUT_CONTENT_DATA';
export const GET_ABOUT_CONTENT_DATA_SUCCESS = 'GET_ABOUT_CONTENT_DATA_SUCCESS';
export const GET_ABOUT_CONTENT_DATA_ERROR = 'GET_ABOUT_CONTENT_DATA_ERROR';

export const GET_ABOUT_INNER_CONTENT_DATA = 'GET_ABOUT_INNER_CONTENT_DATA';
export const GET_ABOUT_INNER_CONTENT_DATA_SUCCESS = 'GET_ABOUT_INNER_CONTENT_DATA_SUCCESS';
export const GET_ABOUT_INNER_CONTENT_DATA_ERROR = 'GET_ABOUT_INNER_CONTENT_DATA_ERROR';

export const GET_CONTACT_CONTENT_DATA = 'GET_CONTACT_CONTENT_DATA';
export const GET_CONTACT_CONTENT_DATA_SUCCESS = 'GET_CONTACT_CONTENT_DATA_SUCCESS';
export const GET_CONTACT_CONTENT_DATA_ERROR = 'GET_CONTACT_CONTENT_DATA_ERROR';

export const GET_HOME_CONTENT_DATA = 'GET_HOME_CONTENT_DATA';
export const GET_HOME_CONTENT_DATA_SUCCESS = 'GET_HOME_CONTENT_DATA_SUCCESS';
export const GET_HOME_CONTENT_DATA_ERROR = 'GET_HOME_CONTENT_DATA_ERROR';

export const GET_PASSWORDS_DATA = 'GET_PASSWORDS_DATA';
export const GET_PASSWORDS_DATA_SUCCESS = 'GET_PASSWORDS_DATA_SUCCESS';
export const GET_PASSWORDS_DATA_ERROR = 'GET_PASSWORDS_DATA_ERROR';

export const GET_CONTEXT_DATA = 'GET_CONTEXT_DATA';
export const GET_CONTEXT_DATA_SUCCESS = 'GET_CONTEXT_DATA_SUCCESS';
export const GET_CONTEXT_DATA_ERROR = 'GET_CONTEXT_DATA_ERROR';

export const IS_PAGE_LOADED = 'IS_PAGE_LOADED';
export const IS_PAGE_LOADED_SUCCESS = 'IS_PAGE_LOADED_SUCCESS';

export const SET_LOAD_PAGE = 'SET_LOAD_PAGE';
export const SET_LOAD_PAGE_SUCCESS = 'SET_LOAD_PAGE_SUCCESS';
