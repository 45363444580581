// Ticket: (FWDNTIPIRF-18) | Date: 23-11-2020 | Change: Changed feature images get method to reflect backend API changes

// @link to fancybox being used - https://obu.edu/_resources/ldp/galleries/fancybox/

import React, { useState, useEffect } from 'react';
import ReactHtmlParser from 'react-html-parser';
import styled from 'styled-components';
import { useParams } from 'react-router';
import { useTransitionHistory } from 'react-route-transition';
import { Helmet } from 'react-helmet';
import { Fragment } from 'react';
import { connect } from 'react-redux';
//import ReactGA from 'react-ga';
import { isSafari, browserName } from 'react-device-detect';

import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import 'react-awesome-slider/dist/styles.css';

import { ReactComponent as SingleNavArrow } from '../../assets/media/arrows/single-nav-arrow.svg';
import { ReactComponent as DoubleNavArrow } from '../../assets/media/arrows/portfolio-double-nav-arrow-spaced.svg';
import './styles/sub-tab-page.scss';
import { setContext } from '../../store/actions/actions';

import RotateScreenImg from '../../assets/media/rotate-screen.png';

const SubMenuTitle = styled.div`
    display: inline-block;
    padding-right: 30px;
    & > p {
        font-family: ${(props) => (props.currentTabSubPageData ? props.currentTabSubPageData.font_family : '')};
        font-size: ${(props) => (props.currentTabSubPageData ? props.currentTabSubPageData.font_size + 'px' : '')};
        color: ${(props) => (props.currentTabSubPageData ? props.currentTabSubPageData.text_color : '')};
        font-weight: ${(props) => (props.currentTabSubPageData ? props.currentTabSubPageData.font_weight : '')};
        font-style: ${(props) => (props.currentTabSubPageData ? props.currentTabSubPageData.font_style : '')};
        font-variant: ${(props) => (props.currentTabSubPageData ? props.currentTabSubPageData.font_variant : '')};
        line-height: ${(props) => (props.currentTabSubPageData ? props.currentTabSubPageData.line_height + 'px' : '')};
        text-decoration: ${(props) => (props.currentTabSubPageData ? props.currentTabSubPageData.text_decoration : '')};
        text-transform: ${(props) => (props.currentTabSubPageData ? props.currentTabSubPageData.text_transform : '')};
        letter-spacing: ${(props) =>
            props.currentTabSubPageData ? props.currentTabSubPageData.letter_spacing + 'px' : ''};
        text-align: ${(props) => (props.currentTabSubPageData ? props.currentTabSubPageData.text_align : '')};
        white-space: nowrap;
    }
`;

const PortfolioList = styled.ul`
    list-style-type: none;
    & > li {
        font-family: ${(props) => (props.currentTabSubPageData ? props.currentTabSubPageData.font_family : '')};
        font-size: ${(props) => (props.currentTabSubPageData ? props.currentTabSubPageData.font_size + 'px' : '')};
        color: ${(props) => (props.currentTabSubPageData ? props.currentTabSubPageData.text_color : '')};
        font-weight: ${(props) => (props.currentTabSubPageData ? props.currentTabSubPageData.font_weight : '')};
        font-style: ${(props) => (props.currentTabSubPageData ? props.currentTabSubPageData.font_style : '')};
        font-variant: ${(props) => (props.currentTabSubPageData ? props.currentTabSubPageData.font_variant : '')};
        line-height: ${(props) => (props.currentTabSubPageData ? props.currentTabSubPageData.line_height + 'px' : '')};
        text-decoration: ${(props) => (props.currentTabSubPageData ? props.currentTabSubPageData.text_decoration : '')};
        text-transform: ${(props) => (props.currentTabSubPageData ? props.currentTabSubPageData.text_transform : '')};
        letter-spacing: ${(props) =>
            props.currentTabSubPageData ? props.currentTabSubPageData.letter_spacing + 'px' : ''};
        text-align: ${(props) => (props.currentTabSubPageData ? props.currentTabSubPageData.text_align : '')};
        cursor: pointer;
    }
    & > li:hover {
        color: ${(props) =>
            props.currentTabSubPageData && props.currentTabSubPageData.text_color === 'White' ? '#000' : '#fff'};
    }
    & > .active {
        color: ${(props) =>
            props.currentTabSubPageData && props.currentTabSubPageData.text_color === 'White' ? '#000' : '#fff'};
    }
`;

// Tab page component
function SubTabPage(props) {
    const { context, setContext, allPageData } = props;

    const [{ menuTitle, subId }] = useState(useParams());
    const [currentTabSubPageData, setCurrentTabSubPageData] = useState(null);
    const [sliderArrowColor, setSliderArrowColor] = useState('');
    const [portfolioContent, setPortfolioContent] = useState(null);
    const [featuredImages, setFeaturedImages] = useState([]);
    const [useFastForward, setUseFastForward] = useState(false);
    const [isTextData, setIsTextData] = useState(false);

    const history = useTransitionHistory();
    const root_pathname = window.location.pathname.split(/[/]/)[1];

    // set content as needed.
    useEffect(() => {
        (async () => {
            if (!context.data.initialLoadProp) {
                //ReactGA.pageview(window.location.pathname);
                const update = {
                    ...context.data,
                    initialLoadProp: true
                };
                await setContext('context', update);
            }
        })();

        enableScalableResize();

        window.onkeyup = onKeyPresses;

        // return the proper data based on `menuTitle`; (redux)
        const returnNeededData = (menuTitle, subId) => {
            switch (menuTitle) {
                case 'visuals':
                    for (const artist of allPageData.data[menuTitle]) if (artist.id === parseInt(subId)) return artist;
                    break;
                case 'writers':
                    for (const artist of allPageData.data[menuTitle]) if (artist.id === parseInt(subId)) return artist;
                    break;
                case '3d-previz':
                    for (const artist of allPageData.data[menuTitle]) if (artist.id === parseInt(subId)) return artist;
                    break;
                case 'graphic-design':
                    for (const artist of allPageData.data[menuTitle]) if (artist.id === parseInt(subId)) return artist;
                    break;
                case 'worldwide':
                    for (const artist of allPageData.data[menuTitle]) if (artist.id === parseInt(subId)) return artist;
                    break;
                case 'corporate':
                    for (const artist of allPageData.data[menuTitle]) if (artist.id === parseInt(subId)) return artist;
                    break;
                default:
                    break;
            }
        };

        const data = returnNeededData(menuTitle, subId);
        let arrowColor = context.data.assortedMenu[root_pathname].select_logo_color;
        setIsTextData(data.ACF?.add_slider_data[0]?.add_content_for_attribute[0]?.select_content_type === 'Content');

        setCSSVariables(root_pathname, data);

        setCurrentTabSubPageData(data);
        setSliderArrowColor(arrowColor);

        // Set first portfolio item by default open or Featured images silder
        if (data?.ACF?.add_slider_data && data?.ACF?.add_slider_data.length > 0) {
            getPortfolioContent(data?.ACF?.add_slider_data[0]);
        }

        if (data?.ACF?.featured_image_gallery && data?.ACF?.featured_image_gallery.length > 0) {
            getFeaturedImages(data?.ACF?.featured_image_gallery);
        }
    }, []);

    /**
     * Sets the CSS variables for user inputted styles from wordpress.
     *
     * @param root_pathanme - category of path. e.g. writers, visuals, etc.
     * @param data - the ACF data for content from wp. used to determine if image or text.
     */
    const setCSSVariables = (root_pathname, data) => {
        const setVariable = document.documentElement.style;
        const content = context.data.themeSettings.portfolio_content;
        const contentData = data.ACF?.add_slider_data[0]?.add_content_for_attribute[0]?.select_content_type;

        if (contentData !== 'Content') {
            setVariable.setProperty('--written-border-width', 'unset');
            setVariable.setProperty('--written-border-color', 'unset');
            setVariable.setProperty('--written-border-style', 'unset');
            return;
        }

        // set border for writers section
        if (root_pathname === 'writers') {
            // written portfolio user inputted values
            const borderWidth = content.portfolio_content_border_width.option_value;
            const borderColor = content.portfolio_content_border_color.option_value;
            const borderStyle = content.portfolio_content_border_style.option_value;

            setVariable.setProperty('--written-border-width', `${borderWidth}px`);
            setVariable.setProperty('--written-border-color', borderColor);
            setVariable.setProperty('--written-border-style', borderStyle);
        } else if (root_pathname === 'worldwide') {
            // worldwide theme settings
            const borderWidth = content.portfolio_content_worldwide_border_width.option_value;
            const borderColor = content.portfolio_content_worldwide_border_color.option_value;
            const borderStyle = content.portfolio_content_worldwide_border_style.option_value;

            setVariable.setProperty('--written-border-width', `${borderWidth}px`);
            setVariable.setProperty('--written-border-color', borderColor);
            setVariable.setProperty('--written-border-style', borderStyle);
        } else {
            setVariable.setProperty('--written-border-width', 'unset');
            setVariable.setProperty('--written-border-color', 'unset');
            setVariable.setProperty('--written-border-style', 'unset');
        }
    };

    /**
     * Allows the #main-item-sub-content to be resized based on screen size.
     */
    const enableScalableResize = () => {
        /* initial load */
        const screensize = window.screen.width;
        // divides to 1
        let baseWidth = window.innerWidth;
        let modalBase = window.innerWidth;

        if (screensize > 1280 && screensize <= 1680) {
            baseWidth = 1300;
        } else if (screensize <= 1920) {
            baseWidth = 1700;
        } else if (screensize > 1920) {
            baseWidth = 1400;
            modalBase = 1700;
        }

        const percentageOn1 = window.innerWidth / baseWidth; // scale(x)
        const percentageOnModal = window.innerWidth / modalBase;
        const setVariable = document.documentElement.style;

        // set scale value
        if (percentageOn1 > 1) setVariable.setProperty('--sub-content-scale', percentageOn1);
        if (percentageOnModal > 1) setVariable.setProperty('--modal-scale', percentageOnModal);

        /* resize */
        window.onresize = (e) => scaleOnResize(e, baseWidth, modalBase);
    };

    /**
     * We zoom or scale on window resize based on browser type
     */
    const scaleOnResize = (event, baseWidth, modalBase) => {
        const setVariable = document.documentElement.style;
        const percentageOn1 = window.innerWidth / baseWidth; // recapture for window resize scope
        const percentageOnModal = window.innerWidth / modalBase;

        // set if greater than 1 and default to 1 if not
        if (percentageOn1 > 1) {
            setVariable.setProperty('--sub-content-scale', percentageOn1);
        } else {
            setVariable.setProperty('--sub-content-scale', 1);
        }

        if (percentageOnModal > 1) {
            setVariable.setProperty('--modal-scale', percentageOnModal);
        } else {
            setVariable.setProperty('--modal-scale', 1);
        }
    };

    const onKeyPresses = (e) => {
        if (e.key === 'ArrowRight')
            if (document.querySelector('.control-next')) document.querySelector('.control-next').click();
        if (e.key === 'ArrowLeft')
            if (document.querySelector('.control-prev')) document.querySelector('.control-prev').click();
        if (e.key === 'Escape') history.push(`/${root_pathname}`);
    };

    const getFeaturedImages = (data) => {
        let featureIamges = [];
        data.forEach((image) => {
            if (image) {
                // FWDNTIPIRF-20 | 23-11-2020 | Changed feature images get method to reflect backend API changes | Start
                featureIamges.push(`<img src= ${image}></div`);
                // FWDNTIPIRF-20 | 23-11-2020 | Changed feature images get method to reflect backend API changes | End
            }
        });
        setFeaturedImages(featureIamges);
    };

    // Modify portfolio content as per the need and rander
    const getPortfolioContent = (data) => {
        let portfolioTitle = data.add_attribute_name;
        let portfolioContent = data.add_content_for_attribute;
        let elements = [];
        // backgroundColor: window.location.pathname.includes('writers') && context.data.themeSettings.portfolio_content.portfolio_content_background_color.option_value

        if (portfolioContent) {
            portfolioContent.forEach((content) => {
                if (content.select_content_type === 'Image') {
                    // elements.push(`<a data-fancybox="gallery" href="${content.add_attribute_data.add_attribute_image}"><img src="${content.add_attribute_data.add_attribute_image}"></a>`);

                    const check = content.add_attribute_data.add_gallery;
                    if (!check) {
			const add_attribute_data = content.add_attribute_data.add_attribute_image;

			// ensure image is correct
			let imageUrl = add_attribute_data || '';

			if (add_attribute_data?.sizes?.hasOwnProperty('1536x1536')) {
			    imageUrl = add_attribute_data?.sizes['1536x1536']; 
			} else if (add_attribute_data?.sizes?.hasOwnProperty('large')) {
			    imageUrl = add_attribute_data?.sizes['large']; 
			}

                        elements.push({
                            type: content.select_content_type,
                            src: {
				full: add_attribute_data.url,
				small: imageUrl 
			    }
                        });
                    } else {
                        const images = content.add_attribute_data.add_gallery;
                        if (images.length > 0 && images !== false) {
                            for (const image of images) {
                                elements.push({
                                    type: content.select_content_type,
                                    src: {
					full: image.url,
					small: image.sizes['1536x1536']
				    }
                                });
                            }
                        }
                    }
                } else if (content.select_content_type === 'Url') {
                    elements.push({
                        type: content.select_content_type,
                        src: content.add_attribute_data.add_attribute_link
                    });
                } else if (content.select_content_type === 'Microsite') {
                    elements.push({
                        type: content.select_content_type,
                        src: content.add_attribute_data.add_attribute_microsite
                    });
		    setUseFastForward(true);
                } else {
                    elements.push({
                        type: content.select_content_type,
                        src: content.add_attribute_data.add_attribute_content
                    });
                }
            });
        }
        if (portfolioTitle === 'GIF') {
            const setVariable = document.documentElement.style;
            setVariable.setProperty('--slide-padding', 0);
            setUseFastForward(true);
        }
        setPortfolioContent({ key: portfolioTitle, content: elements });
    };

    // Update portfolio category content on click
    const renderPorfolioContent = (event, data) => {
        event.preventDefault();
        // setRerender(true);

        // set whether to use fastfoward svg or not.
        if (data?.add_content_for_attribute) {
            const isURL = data?.add_content_for_attribute[0].select_content_type.toLowerCase();
            const setVariable = document.documentElement.style;
            if (isURL === 'url') {
                setUseFastForward(true);
                setVariable.setProperty('--slide-padding', 0);
            } else {
                setUseFastForward(false);
                setVariable.setProperty('--slide-padding', '0.5rem');
            }
        }

        getPortfolioContent(data);
    };

    // Close current sub-tab on X click
    const CloseMenuItem = (event) => {
        event.preventDefault();
        history.push(`/${menuTitle}`);
    };

    const LogoClicked = (event) => {
        // IIFE to set async await.
        (async () => {
            const update = {
                ...context.data,
                currentMenu: null
            };
            await setContext('context', update);
        })();
        history.push('/');
    };

    // coming soon component
    const ComingSoon = (portfolioContent) => {
        if (
            portfolioContent === null ||
            (portfolioContent && portfolioContent.content && portfolioContent.content.length === 0)
        ) {
            const font =
                context.data.themeSettings.portfolio_content.portfolio_content_custom_font.option_value === 'none'
                    ? context.data.themeSettings.portfolio_content.portfolio_content_add_typography.option_value
                          .font_family
                    : context.data.themeSettings.portfolio_content.portfolio_content_custom_font.option_value;
            return (
                <div>
                    <div className="post_des_content_soon">
                        <div className="coming-soon-portfolio">
                            <p
                                className="coming-soon-text"
                                style={{
                                    fontFamily: font,
                                    fontSize:
                                        context.data.themeSettings.portfolio_content.portfolio_content_add_typography
                                            .option_value.font_size + 'px',
                                    color: context.data.themeSettings.portfolio_content.portfolio_content_add_typography
                                        .option_value.text_color,
                                    fontWeight:
                                        context.data.themeSettings.portfolio_content.portfolio_content_add_typography
                                            .option_value.font_weight,
                                    fontStyle:
                                        context.data.themeSettings.portfolio_content.portfolio_content_add_typography
                                            .option_value.font_style,
                                    fontVariant:
                                        context.data.themeSettings.portfolio_content.portfolio_content_add_typography
                                            .option_value.font_variant,
                                    lineHeight:
                                        context.data.themeSettings.portfolio_content.portfolio_content_add_typography
                                            .option_value.line_height + 'px',
                                    textDecoration:
                                        context.data.themeSettings.portfolio_content.portfolio_content_add_typography
                                            .option_value.text_decoration,
                                    textTransform:
                                        context.data.themeSettings.portfolio_content.portfolio_content_add_typography
                                            .option_value.text_transform,
                                    letterSpacing:
                                        context.data.themeSettings.portfolio_content.portfolio_content_add_typography
                                            .option_value.letter_spacing + 'px',
                                    textAlign:
                                        context.data.themeSettings.portfolio_content.portfolio_content_add_typography
                                            .option_value.text_align
                                }}
                            >
                                COMING SOON...
                            </p>
                        </div>
                    </div>
                </div>
            );
        }
    };

    const currentMenu = context.data.assortedMenu[root_pathname];
    const lazyload = browserName === 'Chrome' ? 'eager' : 'lazy';

    const isTall = (imgsrc) => {
	const imageSizeMatch = imgsrc?.match(/(\d+)x(\d+)/) ?? [];

	if (imageSizeMatch.length === 0) {
	    return false;
	}

	const imageSize = imageSizeMatch[0];

	const wH = imageSize.split('x');

	if (wH) {
	    const w = Number(wH[0]);
	    const h = Number(wH[1]);

	    return ratio(w, h) < 1.50;
	}

	return false;
    }

    const ratio = (w, h) => {
	function mdc(w, h) {
	    let resto;
	    do {
		resto = w % h;

		w = h;
		h = resto;

	    } while (resto !== 0);

	    return w;
	}

	const mdcResult = mdc(w, h);


	const width = w/mdcResult;
	const height = h/mdcResult;

	const aspectRatioDecimal = width / height;

	return aspectRatioDecimal;
    }

    return (
        <Fragment>
            {/* <GoogleFontLoader
                fonts={context.data.importFonts.component}
            /> */}
            <div
                id="sub-tab-page"
                style={{
                    boxShadow: `0px 0px 0px 100vw ` + currentMenu.background_color,
                    backgroundColor: currentMenu.background_color
                }}
            >
                <Helmet>
                    <title>
                        {currentTabSubPageData
                            ? context.data.appName + ' | ' + currentTabSubPageData.title.rendered
                            : context.data.appName}
                    </title>
                </Helmet>
                <div
                    onClick={LogoClicked}
                    className="header_logo_img"
                    style={{
                        maxWidth: `${context.data.logoSize}pt`,
                        width: `${context.data.logoSize}pt`
                    }}
                >
                    <img
                        id="header-logo"
                        src={
                            currentMenu.select_logo_color === 'White'
                                ? context.data.themeSettings.white_logo.white_logo[0]
                                : context.data.themeSettings.site_logo.site_logo[0]
                        }
                        alt="logo"
                        style={{ cursor: 'pointer' }}
                    />
                </div>
                <p
                    className="tab-title"
                    onClick={(event) => CloseMenuItem(event, context.data)}
                    style={{
                        fontFamily: context.data.menuStyle.font_family,
                        fontSize: context.data.menuStyle.font_size + 'px',
                        color: currentMenu.select_logo_color,
                        fontWeight: context.data.menuStyle.font_weight,
                        fontStyle: context.data.menuStyle.font_style,
                        fontVariant: context.data.menuStyle.font_variant,
                        lineHeight: context.data.menuStyle.line_height + 'px',
                        textDecoration: context.data.menuStyle.text_decoration,
                        textTransform: context.data.menuStyle.text_transform,
                        letterSpacing: context.data.menuStyle.letter_spacing + 'px',
                        textAlign: context.data.menuStyle.text_align,
                        left: context.data.themeSettings.tab_title.tab_title_left.option_value + 'pt',
                        top: context.data.themeSettings.tab_title.tab_title_top.option_value + 'pt'
                    }}
                >
                    {currentMenu.title}
                </p>
                <span className="close-btn" onClick={(event) => CloseMenuItem(event, context.data)}>
                    X
                </span>
                {currentTabSubPageData && (
                    <div id="menu-item-sub-content">
                        <div className="author-info-container">
                            <div className="portfolio-about-author">
                                <SubMenuTitle
                                    currentTabSubPageData={{
                                        ...context.data.themeSettings.portfolio_title.portfolio_title_add_typography
                                            .option_value,
                                        font_family:
                                            context.data.themeSettings.portfolio_title.portfolio_title_custom_font
                                                .option_value === 'none'
                                                ? context.data.themeSettings.portfolio_title
                                                      .portfolio_title_add_typography.option_value.font_family
                                                : context.data.themeSettings.portfolio_title.portfolio_title_custom_font
                                                      .option_value,
                                        text_color: currentMenu?.select_logo_color
                                    }}
                                >
                                    <p>{currentTabSubPageData ? currentTabSubPageData.title.rendered : ''}</p>
                                </SubMenuTitle>
                                <div
                                    className="author-bio"
                                    style={{
                                        fontFamily:
                                            context.data.themeSettings.portfolio_header.portfolio_header_custom_font
                                                .option_value === 'none'
                                                ? context.data.themeSettings.portfolio_header
                                                      .portfolio_header_add_typography.option_value.font_family
                                                : context.data.themeSettings.portfolio_header
                                                      .portfolio_header_custom_font.option_value,
                                        fontSize:
                                            context.data.themeSettings.portfolio_header.portfolio_header_add_typography
                                                .option_value.font_size + 'px',
                                        color: context.data.themeSettings.portfolio_header
                                            .portfolio_header_add_typography.option_value.text_color,
                                        fontWeight:
                                            context.data.themeSettings.portfolio_header.portfolio_header_add_typography
                                                .option_value.font_weight,
                                        fontStyle:
                                            context.data.themeSettings.portfolio_header.portfolio_header_add_typography
                                                .option_value.font_style,
                                        fontVariant:
                                            context.data.themeSettings.portfolio_header.portfolio_header_add_typography
                                                .option_value.font_variant,
                                        lineHeight:
                                            context.data.themeSettings.portfolio_header.portfolio_header_add_typography
                                                .option_value.line_height + 'px',
                                        textDecoration:
                                            context.data.themeSettings.portfolio_header.portfolio_header_add_typography
                                                .option_value.text_decoration,
                                        textTransform:
                                            context.data.themeSettings.portfolio_header.portfolio_header_add_typography
                                                .option_value.text_transform,
                                        letterSpacing:
                                            context.data.themeSettings.portfolio_header.portfolio_header_add_typography
                                                .option_value.letter_spacing + 'px',
                                        textAlign:
                                            context.data.themeSettings.portfolio_header.portfolio_header_add_typography
                                                .option_value.text_align
                                    }}
                                >
                                    {currentTabSubPageData && ReactHtmlParser(currentTabSubPageData.content.rendered)}
                                </div>
                            </div>
                        </div>

                        <div id={!context.data.isMobile ? 'portfolio' : 'portfolio-mobile'}>
                            <div
                                id={window.location.pathname.includes('writers') ? 'visuals-portfolio' : ''}
                                className="post_list_item"
                            >
                                <PortfolioList
                                    currentTabSubPageData={{
                                        ...context.data.themeSettings.portfolio_menu.portfolio_menu_add_typography
                                            .option_value,
                                        font_family:
                                            context.data.themeSettings.portfolio_menu.portfolio_menu_custom_font
                                                .option_value === 'none'
                                                ? context.data.themeSettings.portfolio_menu
                                                      .portfolio_menu_add_typography.option_value.font_family
                                                : context.data.themeSettings.portfolio_menu.portfolio_menu_custom_font
                                                      .option_value,
                                        color: currentMenu.select_logo_color
                                    }}
                                >
                                    {currentTabSubPageData.ACF.add_slider_data &&
                                    currentTabSubPageData.ACF.add_slider_data !== false
                                        ? currentTabSubPageData.ACF.add_slider_data.map((data, index) => (
                                              <li
                                                  key={index}
                                                  className={
                                                      portfolioContent.key === data.add_attribute_name ? 'active' : ''
                                                  }
                                                  onClick={(event) => renderPorfolioContent(event, data)}
                                              >
                                                  {data.add_attribute_name}
                                              </li>
                                          ))
                                        : ''}
                                </PortfolioList>
                            </div>

                            {/* if no items in portfolio */}
                            {ComingSoon(portfolioContent)}

                            {portfolioContent && portfolioContent.content && portfolioContent.content.length > 0 && (
                                <div
                                    id="post_des_content"
                                    style={{
                                        fontFamily:
                                            context.data.themeSettings.portfolio_content.portfolio_content_custom_font
                                                .option_value === 'none'
                                                ? context.data.themeSettings.portfolio_content
                                                      .portfolio_content_add_typography.option_value.font_family
                                                : context.data.themeSettings.portfolio_content
                                                      .portfolio_content_custom_font.option_value,
                                        fontSize:
                                            context.data.themeSettings.portfolio_content
                                                .portfolio_content_add_typography.option_value.font_size + 'px',
                                        color: context.data.themeSettings.portfolio_content
                                            .portfolio_content_add_typography.option_value.text_color,
                                        fontWeight:
                                            context.data.themeSettings.portfolio_content
                                                .portfolio_content_add_typography.option_value.font_weight,
                                        fontStyle:
                                            context.data.themeSettings.portfolio_content
                                                .portfolio_content_add_typography.option_value.font_style,
                                        fontVariant:
                                            context.data.themeSettings.portfolio_content
                                                .portfolio_content_add_typography.option_value.font_variant,
                                        lineHeight:
                                            context.data.themeSettings.portfolio_content
                                                .portfolio_content_add_typography.option_value.line_height + 'px',
                                        textDecoration:
                                            context.data.themeSettings.portfolio_content
                                                .portfolio_content_add_typography.option_value.text_decoration,
                                        textTransform:
                                            context.data.themeSettings.portfolio_content
                                                .portfolio_content_add_typography.option_value.text_transform,
                                        letterSpacing:
                                            context.data.themeSettings.portfolio_content
                                                .portfolio_content_add_typography.option_value.letter_spacing + 'px',
                                        textAlign:
                                            context.data.themeSettings.portfolio_content
                                                .portfolio_content_add_typography.option_value.text_align
                                    }}
                                >
                                    <Carousel
                                        showStatus={false}
                                        showIndicators={false}
                                        showThumbs={false}
                                        key={portfolioContent.key}
                                        //width={'778px'}
                                        // infiniteLoop={true}
                                        swipeable={false}
                                        onClickItem={(index) => {
                                            // if not in text content data.
                                            if (!isTextData) {
                                                // handle keyPressess for fancybox
                                                window.onkeyup = (e) => {
                                                    if (e.key === 'Escape') {
                                                        window.onkeyup = onKeyPresses;
                                                    }
                                                };
                                                document
                                                    .querySelector('.fancybox-button--close')
                                                    .addEventListener('click', () => {
                                                        window.onkeyup = onKeyPresses;
                                                    });
                                            }
                                        }}
                                        renderArrowPrev={(onClickHandler, hasPrev) =>
                                            hasPrev && (
                                                <Fragment>
                                                    {!useFastForward ? (
                                                        <button
                                                            type="button"
                                                            className={
                                                                currentMenu && currentMenu.select_logo_color === 'White'
                                                                    ? 'control-prev white-control-prev'
                                                                    : 'control-prev black-control-prev'
                                                            }
                                                            onClick={onClickHandler}
                                                        >
                                                            <SingleNavArrow />
                                                        </button>
                                                    ) : (
                                                        // or
                                                        <button
                                                            type="button"
                                                            className={
                                                                currentMenu && currentMenu.select_logo_color === 'White'
                                                                    ? 'control-prev white-control-prev'
                                                                    : 'control-prev black-control-prev'
                                                            }
                                                            onClick={onClickHandler}
                                                        >
                                                            <DoubleNavArrow />
                                                        </button>
                                                    )}
                                                </Fragment>
                                            )
                                        }
                                        renderArrowNext={(onClickHandler, hasNext) =>
                                            hasNext && (
                                                <Fragment>
                                                    {!useFastForward ? (
                                                        <button
                                                            type="button"
                                                            className={
                                                                currentMenu && currentMenu.select_logo_color === 'White'
                                                                    ? 'control-next white-control-next'
                                                                    : 'control-next black-control-next'
                                                            }
                                                            onClick={onClickHandler}
                                                        >
                                                            <SingleNavArrow />
                                                        </button>
                                                    ) : (
                                                        // or
                                                        <button
                                                            type="button"
                                                            className={
                                                                currentMenu && currentMenu.select_logo_color === 'White'
                                                                    ? 'control-next white-control-next'
                                                                    : 'control-next black-control-next'
                                                            }
                                                            onClick={onClickHandler}
                                                        >
                                                            <DoubleNavArrow />
                                                        </button>
                                                    )}
                                                </Fragment>
                                            )
                                        }
                                        style={{
                                            display: portfolioContent.content.length > 1 ? 'block' : 'none'
                                            //border: `${borderWidth}px ${borderColor} ${borderStyle}`
                                        }}
                                    >
                                        {portfolioContent &&
                                            portfolioContent.content &&
                                            portfolioContent.content.map((element, index) => (
                                                <div
                                                    key={index}
                                                    id={index}
                                                    className="portfolio-slider"
                                                    onClick={
                                                        window.location.pathname.includes('writers') || isTextData
                                                            ? openTextModalBox
                                                            : null
                                                    } // only make clickable in /writers tab.
                                                >
                                                    {element.type.toLowerCase() === 'content' && (
                                                        <div
                                                            className={'written_portfolio'}
                                                            style={{
                                                                backgroundColor:
                                                                    menuTitle === 'writers'
                                                                        ? context.data.themeSettings.portfolio_content
                                                                              .portfolio_content_background_color
                                                                              .option_value
                                                                        : context.data.themeSettings.portfolio_content
                                                                              .portfolio_content_worldwide_background_color
                                                                              .option_value
                                                                //border: `${borderWidth}px ${borderColor} ${borderStyle}`
                                                            }}
                                                        >
                                                            {ReactHtmlParser(element.src)}
                                                        </div>
                                                    )}

                                                    {element.type.toLowerCase() === 'image' && (
                                                        <a data-fancybox="gallery" href={element.src.full}>
							    <div className={isTall(element.src.small) ? "image-container-tall" : "image-container"}>
								{context.data.isMobile ? (
								    // no lazy-load for mobile.
								    <img src={element.src.small} />
								) : (
								    <img src={element.src.small} loading={lazyload} />
								)}
							    </div>
                                                        </a>
                                                    )}

                                                    {element.type.toLowerCase() === 'url' && (
                                                        <iframe src={element.src} title='gif'></iframe>
                                                    )}

                                                    {element.type.toLowerCase() === 'microsite' && (
							<Fragment>
							    <div class="microsite-container">
								{
								    !context.data.isMobile && (
									<Fragment>
									    <div class="microsite-toolbox">
										<a
										    data-fancybox data-type="iframe"
										    data-src={element.src}
										    className={
											currentMenu && currentMenu.select_logo_color === 'White'
											    ? 'microsite-full-screen-btn border-top-white border-right-white'
											    : 'microsite-full-screen-btn border-top-black border-right-black'
										    }
										>
										</a>
									    </div>
									    
									    <iframe
										id="microsite-iframe"
										src={element.src}
										title='microsite'
										onLoad={(e) => {
										    const iframe = e.target;
										    iframe.contentWindow.document.body.style.overflowX = 'hidden';
										}}
									    ></iframe>
									</Fragment>
								    )
								}

								{
								    context.data.isMobile && (
									<Fragment>
									    <a data-fancybox data-type="iframe" data-src={element.src} className={'iframe-link'}>
										<iframe
										    id="microsite-iframe"
										    src={element.src}
										    title='microsite'
										    onLoad={(e) => {
											const iframe = e.target;
											iframe.contentWindow.document.body.style.overflowX = 'hidden';
										    }}
										></iframe>
									    </a>
									</Fragment>
								    )
								}
							    </div>
							</Fragment>
                                                    )}
                                                </div>
                                            ))}
                                    </Carousel>
                                </div>
                            )}
                        </div>
                        {context.data.isMobile && (
                            <div className="rotate-screen-section">
                                <img src={RotateScreenImg} alt='' />
                                <div>
                                    <p>Best in landscape mode.</p>
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </div>
            {(window.location.pathname.includes('writers') || isTextData) && currentTabSubPageData && (
                <TextModalBox
                    portfolioContent={portfolioContent}
                    styles={context.data.themeSettings.portfolio_content}
                    onKeyPresses={onKeyPresses}
                />
            )}
        </Fragment>
    );
}

/* Keep text modal outside to prevent from rendering constantly. Also, move to it's own component. */

const TextModalBox = ({ portfolioContent, styles, onKeyPresses }) => {
    let bgColor = {};
    if (window.location.pathname.includes('writers')) {
        bgColor = {
            backgroundColor: styles.portfolio_content_background_color.option_value
        };
    } else if (window.location.pathname.includes('worldwide')) {
        bgColor = {
            backgroundColor: styles.portfolio_content_worldwide_background_color.option_value
        };
    }
    return (
        <div id="text-modal" className="text-modal">
            <div className="modal-nav white-control-prev" onClick={prevTextModalContent}>
                <SingleNavArrow className="arrow-in-text-modal left" />
            </div>

            <div
                className="modal-content"
                style={{
                    fontFamily:
                        styles.portfolio_content_custom_font.option_value === 'none'
                            ? styles.portfolio_content_add_typography.option_value.font_family
                            : styles.portfolio_content_custom_font.option_value,
                    fontSize: styles.portfolio_content_add_typography.option_value.font_size * 1.7 + 'px',
                    color: styles.portfolio_content_add_typography.option_value.text_color,
                    fontWeight: styles.portfolio_content_add_typography.option_value.font_weight,
                    fontStyle: styles.portfolio_content_add_typography.option_value.font_style,
                    fontVariant: styles.portfolio_content_add_typography.option_value.font_variant,
                    lineHeight: styles.portfolio_content_add_typography.option_value.line_height + 'px',
                    textDecoration: styles.portfolio_content_add_typography.option_value.text_decoration,
                    textTransform: styles.portfolio_content_add_typography.option_value.text_transform,
                    letterSpacing: styles.portfolio_content_add_typography.option_value.letter_spacing + 'px',
                    textAlign: styles.portfolio_content_add_typography.option_value.text_align,
                    ...bgColor
                }}
            >
                <span className="text-modal-close" onClick={(e) => closeTextModalBox(e, onKeyPresses)}>
                    &times;
                </span>
                <div className="portfolio-container">
                    {/* <p>Some text in the Modal..</p> */}
                    {portfolioContent &&
                        portfolioContent.content.map((element, index) => (
                            <div key={index} id={index} className="portfolio-slider">
                                <div className="written_portfolio">{ReactHtmlParser(element.src)}</div>
                            </div>
                        ))}
                </div>
            </div>

            <div className="modal-nav white-control-next rotate" onClick={nextTextModalContent}>
                <SingleNavArrow className="arrow-in-text-modal right" />
            </div>
        </div>
    );
};

const nextTextModalContent = (event) => {
    const portfolioSliders = document.querySelector('.modal-content').querySelectorAll('.portfolio-slider');
    const slidersLength = portfolioSliders.length - 1;

    // click bg control content arrows.
    document.querySelector('.control-next').click();

    let i = 0; // track index.
    for (const slider of portfolioSliders) {
        // control when to show arrows on click.
        const nextArrow = document.querySelector('.arrow-in-text-modal.right');
        const prevArrow = document.querySelector('.arrow-in-text-modal.left');

        if (i === slidersLength - 1) {
            nextArrow.style.setProperty('visibility', 'hidden', 'important');
        } else {
            nextArrow.style.setProperty('visibility', 'visible', 'important');
            prevArrow.style.setProperty('visibility', 'visible', 'important');
        }

        if (i < slidersLength) {
            if (slider.style.display === 'block') {
                slider.style.display = 'none';
                portfolioSliders[i + 1].style.display = 'block';
                continue;
            }
            i++;
        }
    }
};

const prevTextModalContent = (event) => {
    const portfolioSliders = document.querySelector('.modal-content').querySelectorAll('.portfolio-slider');
    // const slidersLength = portfolioSliders.length - 1;

    // click bg control content arrows.
    document.querySelector('.control-prev').click();

    let i = 0; // track index.
    for (const slider of portfolioSliders) {
        if (slider.style.display === 'block') {
            if (i === 1) {
                const prevArrow = document.querySelector('.arrow-in-text-modal.left');
                prevArrow.style.setProperty('visibility', 'hidden', 'important');
            }

            if (i > 0) {
                const nextArrow = document.querySelector('.arrow-in-text-modal.right');
                nextArrow.style.setProperty('visibility', 'visible', 'important');

                slider.style.display = 'none';
                portfolioSliders[i - 1].style.display = 'block';
                continue;
            }
        }
        i++;
    }
};

const closeTextModalBox = (event, onKeyPresses) => {
    window.onkeyup = onKeyPresses;
    // show background content
    const backgroundContent = document.querySelector('#menu-item-sub-content');
    backgroundContent.style.setProperty('opacity', '1', 'important');

    const backgroundCloseButton = document.querySelector('.close-btn');
    backgroundCloseButton.style.setProperty('opacity', '1', 'important');

    const portfolioSliders = document.querySelector('.modal-content').querySelectorAll('.portfolio-slider');

    // clear modal content
    for (const slider of portfolioSliders) slider.style.display = 'none';

    // hide text modal box.
    document.querySelector('#text-modal').style.setProperty('display', 'none');
};

const textModalKeyPresses = (e) => {
    if (e.key === 'ArrowRight') {
        const svgArrow = document.querySelector('.modal-nav.white-control-next svg');
        if (svgArrow.style.visibility !== 'hidden') {
            document.querySelector('.modal-nav.white-control-next').click();
        }
    }

    if (e.key === 'ArrowLeft') {
        const svgArrow = document.querySelector('.modal-nav.white-control-prev svg');
        if (svgArrow.style.visibility !== 'hidden') {
            document.querySelector('.modal-nav.white-control-prev').click();
        }
    }
    if (e.key === 'Escape') document.querySelector('.text-modal-close').click();
};

const openTextModalBox = (event) => {
    window.onkeyup = textModalKeyPresses;

    // hide background content
    const backgroundContent = document.querySelector('#menu-item-sub-content');
    const backgroundCloseButton = document.querySelector('.close-btn');
    backgroundContent.style.setProperty('opacity', '0', 'important');
    backgroundCloseButton.style.setProperty('opacity', '0', 'important');

    // display modal
    const textModal = document.querySelector('#text-modal');
    textModal.style.setProperty('display', 'flex');

    const clickedBGContent = event.currentTarget; // the portfolio text content clicked.
    const portfolioSliders = textModal.querySelectorAll('.portfolio-slider');
    for (const slider of portfolioSliders) {
        // display the proper content clicked for the modal display.
        if (slider.id === clickedBGContent.id) {
            slider.style.display = 'block';

            // hide arrows depending on which portfolio is opened.
            const nextArrow = document.querySelector('.arrow-in-text-modal.right');
            const prevArrow = document.querySelector('.arrow-in-text-modal.left');
            if (slider.id === '0') {
                prevArrow.style.setProperty('visibility', 'hidden', 'important');
            } else {
                // set both to show.
                prevArrow.style.setProperty('visibility', 'visible', 'important');
                nextArrow.style.setProperty('visibility', 'visible', 'important');
            }

            if (slider.id === (portfolioSliders.length - 1).toString()) {
                nextArrow.style.setProperty('visibility', 'hidden', 'important');
            }
        }
    }
};

const mapStateToProps = (state) => ({
    context: state.context,
    visuals: state.visuals,
    writers: state.writers,
    previz3d: state.previz3d,
    graphicDesign: state.graphicDesign,
    worldwide: state.worldwide,
    corporate: state.corporate,
    aboutMenus: state.aboutMenus,
    menuItemStyle: state.menuItemStyle,
    allPageData: state.allPageData
});

const mapDispatchToProps = {
    setContext
};

export default connect(mapStateToProps, mapDispatchToProps)(SubTabPage);
