/**
 * The Loader component when the website is opened.
 * 
 */

import React, { Component } from 'react'
import { Fragment } from 'react';
import styled from 'styled-components'
import { connect } from "react-redux";

import { setLoadPage, setIsPageLoaded } from '../../store/actions/actions';

import APIService from "../../services";

const Container = styled.div` 
  width: ${props => props.viewportWidth}px;
  height: ${props => props.viewportHeight}px;
  background-color: #fff;
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity .6s ease .8s;
  z-index: 222222;
  opacity: ${props => props.isPageLoaded ? '0' : '1'};
  width:100%;
`

const Content = styled.div`
  display: flex;
  overflow: hidden;
`

const Square = styled.div`
  width: 20px;
  height: 20px;
  background-color: ${props => props.color};
  transition: transform .4s ease, opacity .3s ease;
  transform: translateY(${props => props.loadingIndex <= props.index ? '40px' : '0'});
  opacity: ${props => props.isPageLoaded ? '0' : '1'};
`

class Loader extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loadingIndex: 0,
            viewportWidth: Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0),
            viewportHeight: Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0),
            colors: [],
            runDidUpdate: true
        }
    }

    componentDidUpdate() {
        // set the loadStatus to true if users cookie token checks out.
        if (this.state.runDidUpdate) {


	    if (this.props.context.data.isContentLoaded) {
		// while loading if the user is away from screen we wait for them.
		// we need this to prevent FOUT.
		if (document.visibilityState === 'hidden') {
		    document.onvisibilitychange = () => {
			if (this.props.context.data.loadFonts) {
			    (async () => {
				 // load fonts.
				 let i = 0;
				 for (const fontName of this.props.context.data.loadFonts) {
				    if (i === this.props.context.data.loadFonts.length - 1) {
					await document.fonts.load(`17px ${fontName}`);
					this.props.setIsPageLoaded('is-page-loaded');
					document.onvisibilitychange = null;
					break;
				    }   
				    await document.fonts.load(`17px ${fontName}`);
				    i++;
				 } 
			    })();
			}
		    }
		}
	    }
	    
            if (this.props.isPageLoaded.status) {

		/**
		 *  This is for email blast to not require password.
		 */
		const queryParams = new URLSearchParams(window.location.search);
		const emailToken = queryParams.get('emailToken');
		const verifyToken = 'oQgmFBuxVffuPlsA';

		if (emailToken) {
		    if (emailToken === verifyToken) {
			this.props.setLoadPage('set-load-page', true);

			const stripHTML = (html) => {
			    let tmp = document.createElement("DIV");
			    tmp.innerHTML = html;
			    return tmp.textContent || tmp.innerText || "";
			}
			// places the token in the users cookies.
			const setTokenCookie = (token, time) => {
			    const expire = stripHTML(time); // expiration in seconds
			    const pathname = window.location.pathname.replace('/', '');

			    if (pathname.includes('/')) {
				document.cookie = `tellavision-path=${encodeURIComponent(stripHTML(token))};max-age=${expire}`;
			    } else {
				document.cookie = `tellavision=${encodeURIComponent(stripHTML(token))};max-age=${expire}`;
			    }
			}
			setTokenCookie(verifyToken, 86400);
		    }
		}
		// end email blast.

                // when password data is received
                if (this.props.context.data.passwords !== null) {
                    const getCookie = (name) => {
                        const value = `; ${document.cookie}`;
                        const parts = value.split(`; ${name}=`);
                        if (parts.length === 2) return parts.pop().split(';').shift();
                    }

                    const stripHTML = (html) => {
                        let tmp = document.createElement("DIV");
                        tmp.innerHTML = html;
                        return tmp.textContent || tmp.innerText || "";
                    }

                    const userToken1 = decodeURIComponent(getCookie('tellavision'));
                    const userToken2 = decodeURIComponent(getCookie('tellavision-path'));

                    // loop the db data to get token.
                    for (const data of this.props.context.data.passwords) {
                        const token = stripHTML(data.excerpt.rendered);

                        // set everything here if all conditions are met.
                        if (token === userToken1 || token === userToken2) {
                            this.props.setLoadPage('set-load-page', true);

                            this.setState({ runDidUpdate: false });
                        }

                        const loadAllFontsContainer = document.querySelector('.loadAllFonts');
                        if (loadAllFontsContainer) loadAllFontsContainer.remove();

                        // remove loader after fade out effect.
                        const loaderContainer = document.querySelector('.loader-container');
                        setTimeout(() => {
                            // if (loaderContainer) loaderContainer.style.setProperty('display', 'none');
                            if (loaderContainer) loaderContainer.remove();
                        }, 1000);
                    }
                }
            }
        }
    }

    componentDidMount() {
        (async () => {
            // load fonts.
            let i = 0;
            if (this.props.context.data.length > 0) {
                for (const fontName of this.props.context.data.loadFonts) {
                    if (i === this.props.context.data.loadFonts.length - 1) {
                        await document.fonts.load(`17px ${fontName}`);
                        break;
                    }
                    await document.fonts.load(`17px ${fontName}`);
                    i++;
                }
            }
        })();

        var up = true
        var value = 0
        var increment = 1


        // leave this since it controls menu loading bar.
        APIService.fetchPrimaryMenu()
            .then(res => {
                let primaryMenu = res.data.items;
                primaryMenu.forEach((menu) => {
                    this.state.colors.push(menu.background_color);
                });

                var ceiling = this.state.colors.length

                const PerformCalc = () => {
                    if (up === true && value <= ceiling) {
                        value += increment
                        this.setState(prevstate => ({ loadingIndex: prevstate.loadingIndex + 1 }))

                        if (value === ceiling) {
                            up = false;
                        }
                    } else {
                        up = false
                        value -= increment;
                        this.setState(prevstate => ({ loadingIndex: prevstate.loadingIndex - 1 }))

                        if (value === 0) {
                            up = true;
                        }
                    }
                }
                setInterval(PerformCalc, 300) // we need to stop this interval.

                window.addEventListener("resize", () => {
                    this.setState({
                        viewportHeight: Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0),
                        viewportWidth: Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
                    })
                })
            })
    }

    preloadElements = () => {
        if (this.state.runDidUpdate) {
            const { context } = this.props;
            const loadFonts = context.data.loadFonts;
            const elements = [];
            const fontWeights = [100, 300, 400, 600, 700, 800];

            if (context.data.loadFonts !== undefined) {
                for (const font of loadFonts) {
                    for (let i = 0; i < fontWeights.length; i++) {
                        elements.push(<p style={{ fontFamily: font, fontWeight: fontWeights[i] }}>.</p>);
                    }
                }
            }

	    const themeSettings = context.data.themeSettings;
	    if (themeSettings !== undefined) {
		const logo = themeSettings.site_logo.site_logo[0];
		const whiteLogo = themeSettings.white_logo.white_logo[0];
	
		elements.push(<img src={logo}/>);
		elements.push(<img src={whiteLogo}/>);
	    }

            return elements;
        }
    }

    render() {
        const {
            colors,
            viewportWidth,
            viewportHeight,
            loadingIndex
        } = this.state;

        const {
            context
        } = this.props;

        return (
            <Fragment>
                <div className='loadAllFonts' style={{ visibility: 'hidden' }}>
                    {this.preloadElements()}
                </div>
                <Container
                    className='loader-container'
                    viewportWidth={viewportWidth}
                    viewportHeight={viewportHeight}
                    isPageLoaded={this.props.isPageLoaded.status}
                >
                    <Content >
                        {
                            colors.map((color, index) => (
                                <Square
                                    key={index}
                                    color={color}
                                    index={index}
                                    loadingIndex={loadingIndex}
                                    isPageLoaded={this.props.isPageLoaded.status}
                                >
                                </Square>
                            ))
                        }
                    </Content>
                </Container>
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    isPageLoaded: state.isPageLoaded,
    passwords: state.passwords,
    primaryMenu: state.primaryMenu,
    context: state.context
});

const mapDispatchToProps = {
    setLoadPage,
    setIsPageLoaded
};

export default connect(mapStateToProps, mapDispatchToProps)(Loader);
