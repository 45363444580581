

import APIService from "../../services";
import {
    GET_PAGE_DATA,
    GET_PAGE_DATA_SUCCESS,
    GET_PAGE_DATA_ERROR,
    GET_VISUALS_DATA,
    GET_VISUALS_DATA_SUCCESS,
    GET_VISUALS_DATA_ERROR,
    GET_WRITERS_DATA,
    GET_3D_PREVIZ_DATA,
    GET_GRAPHIC_DESIGN_DATA,
    GET_WORLDWIDE_DATA,
    GET_WRITERS_DATA_SUCCESS,
    GET_3D_PREVIZ_DATA_SUCCESS,
    GET_GRAPHIC_DESIGN_DATA_SUCCESS,
    GET_WORLDWIDE_DATA_SUCCESS,
    GET_WRITERS_DATA_ERROR,
    GET_3D_PREVIZ_DATA_ERROR,
    GET_GRAPHIC_DESIGN_DATA_ERROR,
    GET_WORLDWIDE_DATA_ERROR, GET_CORPORATE_DATA_ERROR, GET_CORPORATE_DATA_SUCCESS, GET_CORPORATE_DATA,
    GET_ABOUT_MENUS_DATA,
    GET_ABOUT_MENUS_DATA_SUCCESS,
    GET_ABOUT_MENUS_DATA_ERROR,
    GET_PRIMARY_MENU_DATA,
    GET_PRIMARY_MENU_DATA_SUCCESS,
    GET_PRIMARY_MENU_DATA_ERROR,
    GET_ABOUT_CONTENT_DATA,
    GET_ABOUT_CONTENT_DATA_SUCCESS,
    GET_ABOUT_CONTENT_DATA_ERROR,
    GET_CONTACT_CONTENT_DATA,
    GET_CONTACT_CONTENT_DATA_SUCCESS,
    GET_CONTACT_CONTENT_DATA_ERROR,
    GET_HOME_CONTENT_DATA,
    GET_HOME_CONTENT_DATA_SUCCESS,
    GET_HOME_CONTENT_DATA_ERROR,
    GET_PASSWORDS_DATA,
    GET_PASSWORDS_DATA_SUCCESS,
    GET_PASSWORDS_DATA_ERROR,
    GET_CONTEXT_DATA,
    GET_CONTEXT_DATA_SUCCESS,
    GET_CONTEXT_DATA_ERROR,
    GET_ABOUT_INNER_CONTENT_DATA,
    GET_ABOUT_INNER_CONTENT_DATA_SUCCESS,
    GET_ABOUT_INNER_CONTENT_DATA_ERROR,
    IS_PAGE_LOADED,
    IS_PAGE_LOADED_SUCCESS,
    SET_LOAD_PAGE,
    SET_LOAD_PAGE_SUCCESS
} from "./types";

// if all the api calls for the data is finished.
export const setIsPageLoaded = (name) => async dispatch => {
    // it will always be set to true when this function is called.
    dispatch({
        type: getSuccessActionFromTitle(name),
        payload: true
    });
}

// actually load the page for the viewer.
export const setLoadPage = (name, status = false) => async dispatch => {

    dispatch({
        type: getSuccessActionFromTitle(name),
        payload: status
    });

}

const RETRY_COUNTER = 3;

export const getAllPageData = (name) => async dispatch => {
    dispatch({
        type: getInitialActionFromTitle(name)
    });

    let counter = 0;

    while (true) {
        try {
            const content = await Promise.all([
                APIService.fetchAllArtists(), // 0
                APIService.fetchAboutMenus(), // 1
            ]);

            const allArtists = content[0].data;

            const data = {
                'aboutMenus': content[1].data,
                'visuals': allArtists.hasOwnProperty('visuals') ? allArtists['visuals'] : [],
                'writers': allArtists.hasOwnProperty('visuals') ? allArtists['writers'] : [],
                '3d-previz': allArtists.hasOwnProperty('3d-previz') ? allArtists['3d-previz'] : [],
                'graphic-design': allArtists.hasOwnProperty('graphic-design') ? allArtists['graphic-design'] : [],
                'worldwide': allArtists.hasOwnProperty('worldwide') ? allArtists['worldwide'] : [],
                'corporates': allArtists.hasOwnProperty('corporates') ? allArtists['corporates'] : [],
                'passwords': allArtists['passwords']
            }

            dispatch({
                type: getSuccessActionFromTitle(name),
                payload: data,
            });
            break;
        } catch (e) {
            if (counter >= RETRY_COUNTER) {
                dispatch({
                    type: getErrorActionFromTitle(name),
                    payload: e.response.data.message,
                });
                break;
            }
            console.log('retrying');
            counter++;
        }
    }
}

export const getPageData = (menuTitle) => async dispatch => {
    dispatch({
        type: getInitialActionFromTitle(menuTitle),
    });

    let counter = 0;

    while (true) {
        try {
            const data = await APIService.fetchMenuItemMainPageData(menuTitle);
            dispatch({
                type: getSuccessActionFromTitle(menuTitle),
                payload: data.data,
            });
            break;
        } catch (e) {
            if (counter >= RETRY_COUNTER) {
                dispatch({
                    type: getErrorActionFromTitle(menuTitle),
                    payload: e.response.data.message,
                });
                break;
            }
            console.log('retrying');
            counter++;
        }
    }
};

export const fetchPageContent = (pageID) => async dispatch => {
    dispatch({
        type: getInitialActionFromTitle(pageID),
    });

    let counter = 0;

    while (true) {
        try {
            const data = await APIService.fetchPageContent(pageID);
            dispatch({
                type: getSuccessActionFromTitle(pageID),
                payload: data.data,
            });
            break;
        } catch (e) {
            if (counter >= RETRY_COUNTER) {
                dispatch({
                    type: getErrorActionFromTitle(pageID),
                    payload: e.response.data.message,
                });
                break;
            }
            console.log('retrying');
            counter++;
        }
    }
};

export const fetchInnerAbout = (name) => async dispatch => {
    dispatch({
        type: getInitialActionFromTitle(name)
    });

    let counter = 0;

    while (true) {
        try {
            const aboutContent = await Promise.all([APIService.fetchPageContent(273), APIService.fetchPageContent(270)]);
            const data = {
                clients: aboutContent[0],
                news: aboutContent[1]
            }
            dispatch({
                type: getSuccessActionFromTitle(name),
                payload: data,
            });
            break;
        } catch (e) {
            if (counter >= RETRY_COUNTER) {
                dispatch({
                    type: getErrorActionFromTitle(name),
                    payload: e.response.data.message,
                });
                break;
            }
            console.log('retrying');
            counter++;
        }
    }
}

export const fetchAboutMenus = (menuTitle) => async dispatch => {
    dispatch({
        type: getInitialActionFromTitle(menuTitle)
    });

    let counter = 0;

    while (true) {
        try {
            const data = await APIService.fetchAboutMenus();
            dispatch({
                type: getSuccessActionFromTitle(menuTitle),
                payload: data.data,
            });
            break;
        } catch (e) {
            if (counter >= RETRY_COUNTER) {
                dispatch({
                    type: getErrorActionFromTitle(menuTitle),
                    payload: e.response.data.message,
                });
                break;
            }
            console.log('retrying');
            counter++;
        }
    }
};

export const fetchPrimaryMenu = (menuTitle) => async dispatch => {
    dispatch({
        type: getInitialActionFromTitle(menuTitle)
    });

    let counter = 0;

    while (true) {
        try {
            const data = await APIService.fetchPrimaryMenu();
            dispatch({
                type: getSuccessActionFromTitle(menuTitle),
                payload: data.data,
            });
            break;
        } catch (e) {
            if (counter >= RETRY_COUNTER) {
                dispatch({
                    type: getErrorActionFromTitle(menuTitle),
                    payload: e.response.data.message,
                });
                break;
            }
            console.log('retrying');
            counter++;
        }
    }
}

export const fetchHomeContent = (menuTitle) => async dispatch => {
    dispatch({
        type: getInitialActionFromTitle(menuTitle)
    });

    let counter = 0;

    while (true) {
        try {
            const data = await APIService.fetchHomeContent();
            dispatch({
                type: getSuccessActionFromTitle(menuTitle),
                payload: data.data,
            });
            break;
        } catch (e) {
            if (counter >= RETRY_COUNTER) {
                dispatch({
                    type: getErrorActionFromTitle(menuTitle),
                    payload: e.response.data.message,
                });
                break;
            }
            console.log('retrying');
            counter++;
        }
    }
}

export const fetchPasswords = (menuTitle) => async dispatch => {
    dispatch({
        type: getInitialActionFromTitle(menuTitle)
    });

    let counter = 0;

    while (true) {
        try {
            const data = await APIService.fetchPasswords();
            dispatch({
                type: getSuccessActionFromTitle(menuTitle),
                payload: data.data,
            });
            break;
        } catch (e) {
            if (counter >= RETRY_COUNTER) {
                dispatch({
                    type: getErrorActionFromTitle(menuTitle),
                    payload: e.response.data.message,
                });
                break;
            }
            console.log('retrying');
            counter++;
        }
    }
}

export const setContext = (title, context) => async dispatch => {
    dispatch({
        type: getInitialActionFromTitle(title)
    });

    let counter = 0;

    while (true) {
        try {
            const data = context;
            await dispatch({
                type: getSuccessActionFromTitle(title),
                payload: data
            });
            break;
        } catch (e) {
            if (counter >= RETRY_COUNTER) {
                await dispatch({
                    type: getErrorActionFromTitle(title),
                    payload: e.response.data.message
                });
                break;
            }
            console.log('retrying');
            counter++;
        }
    }
}

const getInitialActionFromTitle = (menuTitle) => {
    switch (menuTitle) {
        case ('page-data'):
            return GET_PAGE_DATA;
        case ('visuals'):
            return GET_VISUALS_DATA;
        case ('writers'):
            return GET_WRITERS_DATA;
        case ('3d-previz'):
            return GET_3D_PREVIZ_DATA;
        case ('graphic-design'):
            return GET_GRAPHIC_DESIGN_DATA;
        case ('worldwide'):
            return GET_WORLDWIDE_DATA;
        case ('corporates'):
            return GET_CORPORATE_DATA;
        case ('about-menus'):
            return GET_ABOUT_MENUS_DATA;
        case ('primary-menu'):
            return GET_PRIMARY_MENU_DATA;
        case (13):
            return GET_ABOUT_CONTENT_DATA;
        case (17):
            return GET_CONTACT_CONTENT_DATA;
        case ('home-content'):
            return GET_HOME_CONTENT_DATA;
        case ('passwords'):
            return GET_PASSWORDS_DATA;
        case ('context'):
            return GET_CONTEXT_DATA;
        case ('inner-about'):
            return GET_ABOUT_INNER_CONTENT_DATA;
        case ('is-page-loaded'):
            return IS_PAGE_LOADED;
        case ('set-load-page'):
            return SET_LOAD_PAGE;
        default:
            return {};
    }
};

const getSuccessActionFromTitle = (menuTitle) => {
    switch (menuTitle) {
        case ('page-data'):
            return GET_PAGE_DATA_SUCCESS;
        case ('visuals'):
            return GET_VISUALS_DATA_SUCCESS;
        case ('writers'):
            return GET_WRITERS_DATA_SUCCESS;
        case ('3d-previz'):
            return GET_3D_PREVIZ_DATA_SUCCESS;
        case ('graphic-design'):
            return GET_GRAPHIC_DESIGN_DATA_SUCCESS;
        case ('worldwide'):
            return GET_WORLDWIDE_DATA_SUCCESS;
        case ('corporates'):
            return GET_CORPORATE_DATA_SUCCESS;
        case ('about-menus'):
            return GET_ABOUT_MENUS_DATA_SUCCESS;
        case ('primary-menu'):
            return GET_PRIMARY_MENU_DATA_SUCCESS;
        case (13):
            return GET_ABOUT_CONTENT_DATA_SUCCESS;
        case (17):
            return GET_CONTACT_CONTENT_DATA_SUCCESS;
        case ('home-content'):
            return GET_HOME_CONTENT_DATA_SUCCESS;
        case ('passwords'):
            return GET_PASSWORDS_DATA_SUCCESS;
        case ('context'):
            return GET_CONTEXT_DATA_SUCCESS;
        case ('inner-about'):
            return GET_ABOUT_INNER_CONTENT_DATA_SUCCESS;
        case ('is-page-loaded'):
            return IS_PAGE_LOADED_SUCCESS;
        case ('set-load-page'):
            return SET_LOAD_PAGE_SUCCESS;
        default:
            return {};
    }
};

const getErrorActionFromTitle = (menuTitle) => {
    switch (menuTitle) {
        case ('page-data'):
            return GET_PAGE_DATA_ERROR;
        case ('visuals'):
            return GET_VISUALS_DATA_ERROR;
        case ('writers'):
            return GET_WRITERS_DATA_ERROR;
        case ('3d-previz'):
            return GET_3D_PREVIZ_DATA_ERROR;
        case ('graphic-design'):
            return GET_GRAPHIC_DESIGN_DATA_ERROR;
        case ('worldwide'):
            return GET_WORLDWIDE_DATA_ERROR;
        case ('corporates'):
            return GET_CORPORATE_DATA_ERROR;
        case ('about-menus'):
            return GET_ABOUT_MENUS_DATA_ERROR;
        case ('primary-menu'):
            return GET_PRIMARY_MENU_DATA_ERROR;
        case (13):
            return GET_ABOUT_CONTENT_DATA_ERROR;
        case (17):
            return GET_CONTACT_CONTENT_DATA_ERROR;
        case ('home-content'):
            return GET_HOME_CONTENT_DATA_ERROR;
        case ('passwords'):
            return GET_PASSWORDS_DATA_ERROR;
        case ('context'):
            return GET_CONTEXT_DATA_ERROR;
        case ('inner-about'):
            return GET_ABOUT_INNER_CONTENT_DATA_ERROR;
        default:
            return {};
    }
};
