import { combineReducers } from "redux";

import corporate from "./corporate";
import worldwide from './worldwide';
import graphicDesign from './graphic-design';
import previz3d from "./3d-previz";
import visuals from "./visuals";
import writers from "./writers";
import aboutMenus from "./about-menus";
import primaryMenu from "./primary-menu";
import aboutContent from "./about-content";
import contactContent from "./contact-content";
import homeContent from "./home-content";
import passwords from "./passwords";
import context from "./context";
import isPageLoaded from './is-page-loaded';
import setLoadPage from './set-load-page';
import fetchInnerAbout from "./inner-about-content";
import allPageData from "./get-page-data";

export default combineReducers({
    context,
    corporate,
    worldwide,
    graphicDesign,
    previz3d,
    visuals,
    writers,
    aboutMenus,
    primaryMenu,
    aboutContent,
    contactContent,
    homeContent,
    passwords,
    isPageLoaded,
    setLoadPage,
    fetchInnerAbout,
    allPageData
});
